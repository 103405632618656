import React, {useState} from 'react';
import {Modal, Button} from 'semantic-ui-react';
import {notifications} from '../../utils/notification-service';
import {useLocationCreate} from './locaiton-create-update-component';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus} from '@fortawesome/pro-regular-svg-icons';
import {AsyncStateContainer} from '../../components/async-state-container';

type useLocationCreateModal = {
  handleCreatedSuccess: () => void;
  loadingAccount?: boolean;
  accountId?: number;
  accountNumber?: string;
  disableAccountSelector?: boolean;
};

export const useLocationCreateModal = (props: useLocationCreateModal) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleCreatedSuccess = () => {
    notifications.success('Location Created');
    setModalOpen(false);
    props.handleCreatedSuccess();
  };

  const handleCancel = () => {
    setModalOpen(false);
  };

  const locationCreate = useLocationCreate({
    handleCancel,
    handleCreatedSuccess,
    fluid: true,
    accountId: props.accountId,
    accountNumber: props.accountNumber,
    disableAccountSelector: props.disableAccountSelector,
  });

  const loading =
    props.loadingAccount !== null &&
    props.loadingAccount !== undefined &&
    props.loadingAccount;

  const component = (
    <AsyncStateContainer loading={loading}>
      <Modal
        trigger={
          <Button
            onClick={() => {
              setModalOpen(true);
            }}
            primary
          >
            <FontAwesomeIcon icon={faPlus} /> New Location
          </Button>
        }
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      >
        <Modal.Header>Add New Location</Modal.Header>
        <Modal.Content>{locationCreate.component}</Modal.Content>
      </Modal>
    </AsyncStateContainer>
  );

  return {component};
};
