import css from '@emotion/css/macro';
import {faExclamationTriangle} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {cx} from 'emotion';
import React from 'react';
import {Env} from '../config/env-vars';

const envLabelMap = {
  local: 'LOCAL',
  development: 'TEST',
  stage: 'STAGING',
};

export const envTag: string = envLabelMap[Env.name || ''];

export const NonProductionWarning: React.FC = ({children}) => {
  return (
    <div css={styles}>
      <div className={cx('environment-warning', Env.name)}>
        {envTag ? (
          <>
            <FontAwesomeIcon icon={faExclamationTriangle} />
            &nbsp; You are on the {envTag} environment.
          </>
        ) : null}
      </div>
      {children}
    </div>
  );
};
const styles = css`
  .environment-warning {
    text-align: center;
    padding: 3px 0 2px;

    &.local {
      background-color: #000;
      color: #fff;
    }

    &.development {
      background-color: #e04761;
      color: #fff;
    }

    &.stage {
      background-color: #237190;
      color: #fff;
    }
  }
`;
