import css from '@emotion/css/macro';
import {
  faPencil,
  faPlus,
  faInfoCircle,
} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useCallback, useMemo, useState} from 'react';
import {useAsyncRetry} from 'react-use';
import {Button, Segment, Dropdown} from 'semantic-ui-react';
import {AsyncStateContainer} from '../components/async-state-container';
import {PageTitle} from '../components/page-title';
import {buildPath, routes} from '../routes';
import {AdvancedPagedRequest} from '../utils';
import {} from '../api/generated/enums';
import {
  AccountsService,
  MeterReadingsService,
  MeterReadingSummaryDto,
  LocationsService,
  LocationSummaryDto,
  AccountDetailDto,
} from '../api/generated';
import {
  usePagedDataTable,
  PagedDataTableConfig,
  renderEditButton,
} from '../hooks/use-paged-data-table';
import {useRouteMatch, Link} from 'react-router-dom';
import {BasicPage} from '../basic-page';
import {DataList} from '../components/data-list';
import moment from 'moment';
import {Flex} from '../components/flex';
import {Tooltip} from '../components/tooltip';
import {useAddExistingLocationToAccount} from './add-existing-location-to-account-modal';
import {NotesModal} from './account-note-listing-modal';
import {DeleteButton} from '../components/confirm-delete-button';
import {notifications} from '../utils/notification-service';
import {useLocationCreateModal} from '../locations/location-create-update/localtion-create-modal';

type FetchParams = Parameters<
  typeof MeterReadingsService.getAllByLocationId
>['0'];

export const AccountDashboard = () => {
  const match = useRouteMatch<{id: string}>();
  const id = Number(match.params.id);
  const [selectedLocation, setSelectedLocation] = useState(0);
  const addExistingLocationToAccount = useAddExistingLocationToAccount({
    accountId: id,
    onSubmitSuccess: () => {
      fetchLocations.retry();
      addExistingLocationToAccount.setOpen(false);
    },
  });

  const fetchAccount = useAsyncRetry(async () => {
    const {data} = await AccountsService.getById({id});
    return data;
  }, [id]);

  const account = fetchAccount.value;

  const locationCreateModal = useLocationCreateModal({
    handleCreatedSuccess: () => {
      fetchLocations.retry();
    },
    accountId: id,
    accountNumber:
      account === undefined || account === null ? '' : account.accountNumber,
    disableAccountSelector: true,
    loadingAccount: fetchAccount.loading,
  });

  const locationSelectedInput = (e, data) => {
    if (data.value !== '') {
      setSelectedLocation(data.value);
    } else {
      setSelectedLocation(0);
    }
  };

  const handleDissociateLocation = async (locationId: number) => {
    const response = await LocationsService.dissociateLocationWithAccount({
      body: {locationId},
    });

    if (response.hasErrors) {
      notifications.error(
        response.errors.reduce((acc, error) => {
          return acc + `\r\n${error.errorMessage}`;
        }, '')
      );
    } else {
      fetchLocations.retry();
      notifications.success('Location dissociated from Account');
    }
  };

  const fetchLocations = useAsyncRetry(async () => {
    const {data} = await LocationsService.getAllByAccountId({accountId: id});

    if (!data || !data.items || data.items.length === 0) {
      return {items: [] as LocationSummaryDto[]};
    }

    setSelectedLocation(data.items[0].id);
    return data;
  }, [id]);

  const locations = fetchLocations.value;

  const mappedLocations = (locations ? locations.items : []).map((x) => {
    return {
      key: x.id,
      text: x.locationNumber,
      value: x.id,
    };
  });

  const url = buildPath(routes.portal.accounts.update, {
    id: id,
  });

  const createNewMeterReadingUrl = buildPath(
    routes.portal.meterReadings.create,
    {
      accountId: id,
    }
  );

  const additionalParams = useMemo<FetchParams>(() => {
    return {
      locationId: selectedLocation,
    };
  }, [selectedLocation]);

  const fetchMeterReadings = useCallback(
    (x: AdvancedPagedRequest<MeterReadingSummaryDto>) =>
      MeterReadingsService.getAllByLocationId({
        ...additionalParams,
        ...x,
      } as FetchParams),
    [additionalParams]
  );

  const newMeterReadingButton = (props: {disabled: boolean}) => {
    return (
      <Button
        as={Link}
        to={createNewMeterReadingUrl}
        disabled={props.disabled}
        primary
      >
        <FontAwesomeIcon icon={faPlus} /> New Meter Reading
      </Button>
    );
  };

  const pagedDataTable = usePagedDataTable(fetchMeterReadings, TableConfig, {
    actions: (
      <>
        {!locations || locations.items.length === 0 ? (
          <div css={meterReadingStyles}>
            <Flex.Row>
              <Flex.Box>
                <Tooltip label="Add a location before creating a meter reading.">
                  <FontAwesomeIcon
                    className="new-meter-reading-info-icon"
                    icon={faInfoCircle}
                  />
                </Tooltip>
              </Flex.Box>
              <Flex.Box>
                <div>{newMeterReadingButton({disabled: true})}</div>
              </Flex.Box>
            </Flex.Row>
          </div>
        ) : (
          <>
            <Flex.Row>
              <Flex.Box>
                <Dropdown
                  fieldLabel="Associated Account"
                  fieldName="accountId"
                  options={mappedLocations}
                  defaultValue={
                    mappedLocations.length > 0
                      ? mappedLocations[0].value
                      : undefined
                  }
                  onChange={locationSelectedInput}
                  selection
                />
              </Flex.Box>
              <Flex.Box>
                <div style={{marginLeft: '1rem'}}></div>
              </Flex.Box>
              <Flex.Box>
                <div>{newMeterReadingButton({disabled: false})}</div>
              </Flex.Box>
            </Flex.Row>
          </>
        )}
      </>
    ),
  });

  const mapServices = (acct: AccountDetailDto) => {
    var services = acct.appliedServiceNames.reduce(
      (acc, curr) => {
        acc.push({label: '', value: curr, labelEnding: ''});
        return acc;
      },
      [{label: 'Services', value: '', labelEnding: ':'}]
    );

    return services;
  };

  return (
    <AsyncStateContainer {...fetchAccount}>
      {account && (
        <BasicPage
          title={[
            {title: 'Accounts', url: routes.portal.accounts.listing},
            {
              title: 'Account Details',
            },
          ]}
        >
          <div className="details-flex-container" css={styles}>
            <Segment className="account-card" css={styles}>
              <div className="card-header" style={{marginBottom: '1.375rem'}}>
                <span style={{fontSize: '1.71428571em', fontWeight: 'bold'}}>
                  {account.name}{' '}
                  <span className="muted">({`${account.status}`})</span>
                </span>
                <div className="edit-button left-buttons">
                  <Flex.Row>
                    <Flex.Box>
                      <NotesModal accountId={account.id} />
                    </Flex.Box>
                    <Flex.Box>
                      <Button
                        className="clear"
                        basic
                        icon
                        as={Link}
                        to={url}
                        aria-label={`Edit account`}
                      >
                        <FontAwesomeIcon icon={faPencil} size="lg" />
                      </Button>
                    </Flex.Box>
                  </Flex.Row>
                </div>
              </div>
              <DataList
                data={[
                  {
                    label: 'Account Number',
                    value: account.accountNumber,
                  },
                  {
                    label: 'Name',
                    value: account.name,
                  },
                  {
                    label: 'Email',
                    value: account.email,
                  },
                  {
                    label: 'Phone Number',
                    value: account.phoneNumber,
                  },
                ].concat(mapServices(account))}
              />
            </Segment>
            <div style={{marginLeft: '2rem'}}></div>
            <Segment className="account-card" css={styles}>
              <div className="card-header" style={{marginBottom: '1.375rem'}}>
                <span style={{fontSize: '1.71428571em', fontWeight: 'bold'}}>
                  Locations
                </span>
                <div className="left-buttons">
                  {locationCreateModal.component}
                  {addExistingLocationToAccount.component}
                </div>
              </div>
              <AsyncStateContainer {...fetchLocations}>
                {locations && (
                  <DataList
                    data={locations.items.map((x) => {
                      var locationText = `${x.addressLine1}${
                        !x.addressLine2 ? '' : ` ${x.addressLine2}`
                      }, ${x.city}, ${x.state}, ${x.zipCode}`;

                      return {
                        label: (
                          <span style={{alignItems: 'center'}}>
                            {x.locationNumber}
                          </span>
                        ),
                        value: (
                          <>
                            {
                              <div className="card-header">
                                <span>{locationText}</span>
                                <span className="left-buttons">
                                  <DeleteButton
                                    style={{
                                      paddingTop: '0px',
                                      paddingBottom: '0px',
                                    }}
                                    onConfirm={() => {
                                      handleDissociateLocation(x.id);
                                    }}
                                    text=""
                                    icon
                                    tertiary
                                  />
                                </span>
                              </div>
                            }
                          </>
                        ),
                      };
                    })}
                  />
                )}
              </AsyncStateContainer>
            </Segment>
          </div>
          <br />
          <PageTitle title="Meter Readings" />
          <div>{pagedDataTable}</div>
        </BasicPage>
      )}
    </AsyncStateContainer>
  );
};

const TableConfig = PagedDataTableConfig(
  MeterReadingsService.getAllByAccountId,
  {
    columns: [
      {
        header: '',
        render: (item) => (
          <>
            {renderEditButton({
              item,
              descriptor: 'meter readings',
              route: routes.portal.meterReadings.detail,
            })}
          </>
        ),
        cellProps: {
          collapsing: true,
        },
      },
      {
        header: 'Reading Date',
        sortable: 'readingDate',
        render: (item) => `${moment(item.readingDate).format('MM/DD/YYYY')}`,
      },
      {
        header: 'Location Number',
        column: 'locationNumber',
      },
      {
        header: 'Meter Number',
        column: 'meterNumber',
      },
      {
        header: 'Reading Amount',
        column: 'readingAmount',
      },
      {
        header: 'Amount Due',
        column: 'amountDue',
      },
      {
        header: 'Amount Received',
        column: 'amountReceived',
      },
      {
        header: 'Comments',
        column: 'comments',
      },
    ],
    searchFieldNames: [
      'readingAmount',
      'comments',
      'locationNumber',
      'comments',
      'meterNumber',
    ],
    defaultSort: {
      column: 'readingDate',
      direction: 'DESC',
    },
  }
);

const styles = css`
  &.details-flex-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
  }

  &.account-card {
    margin-bottom: 15px !important;
    margin-top: 0px !important;
    flex: 1;

    .account-balance {
      align-self: flex-start;
      .ui.statistic {
        margin-top: 0;
        > .value {
          font-size: 2rem !important;
        }
      }
    }

    .card-header {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .left-buttons {
        margin-left: auto;
      }
    }
  }
`;

const meterReadingStyles = css`
  .new-meter-reading-info-icon {
    margin-right: 10px;
    margin-top: 11px;
    color: #33b5e5;
  }
`;
