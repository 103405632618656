import React, {useState} from 'react';
import {AccountsService, LocationsService} from '../../api/generated';
import {throttle} from 'lodash';
import {useOrganizationContext} from '../../selectors';
import {routes} from '../../routes';
import {Form} from '../../forms';
import {Link} from 'react-router-dom';
import {States} from '../../api/generated/enums';
import {useAsync, useAsyncFn} from 'react-use';
import {notifications} from '../../utils/notification-service';
import {AsyncStateContainer} from '../../components/async-state-container';
import {DeleteButton} from '../../components/confirm-delete-button';
import {Button} from 'semantic-ui-react';

const fetchAccounts = async (
  text,
  organizationId,
  setItemAccountOptions,
  setItemAccountsLoading
) => {
  const {data} = await AccountsService.getAllByOrganizationId({
    searchFieldNames: ['AccountNumber'],
    searchSearchText: text,
    organizationId: organizationId,
  });

  const options = !data
    ? []
    : data.items.map((x) => {
        return {
          key: x.id,
          text: x.accountNumber,
          value: x.id,
        };
      });

  setItemAccountOptions(options);
  setItemAccountsLoading(false);
};

const throttledFetchAccounts = throttle(fetchAccounts, 1000);

type useLocationCreate = {
  handleCancel: () => void;
  handleCreatedSuccess: () => void;
  fluid: boolean;
  accountId?: number;
  accountNumber?: string;
  disableAccountSelector?: boolean;
};

export const useLocationCreate = (props: useLocationCreate) => {
  const context = useOrganizationContext();
  const organizationId = context.organizationId as number;

  const onSubmit = async (values) => {
    values.organizationId = organizationId;
    const response = await LocationsService.create({body: values});
    if (response.hasErrors) {
      return response;
    }

    props.handleCreatedSuccess();
  };

  const defaultAccountOptions =
    props.accountId === undefined || props.accountNumber === undefined
      ? []
      : [
          {
            key: props.accountId,
            text: props.accountNumber,
            value: props.accountId,
          },
        ];

  var component = (
    <Form.Container>
      <Form
        onSubmit={onSubmit}
        autoComplete="off"
        initialValues={{accountId: props.accountId}}
        render={() => (
          <>
            <Form.Section title="Location Information">
              <FormFields
                fluid={props.fluid}
                defaultAccountOptions={defaultAccountOptions}
                disableAccountSelector={props.disableAccountSelector}
              />
            </Form.Section>
            <div className="form-actions">
              <Form.Button type="submit" primary>
                Create Location
              </Form.Button>
              <Button secondary onClick={() => props.handleCancel()}>
                Cancel
              </Button>
            </div>
          </>
        )}
      />
    </Form.Container>
  );

  return {
    component,
  };
};

type useLocationDetails = {
  locationId: number;
  fluid: boolean;
  handleCancel: () => void;
  handleUpdatedSuccess: () => void;
  handleDeletedSuccess: () => void;
  disableAccountSelector?: boolean;
};

export const useLocationDetails = (props: useLocationDetails) => {
  const fetchLocation = useAsync(async () => {
    const {data} = await LocationsService.getById({
      id: props.locationId,
    });
    return data;
  }, [props.locationId]);

  const location = fetchLocation.value || undefined;

  const onSubmit = async (values) => {
    const response = await LocationsService.update({
      id: props.locationId,
      body: values,
    });
    if (response.hasErrors) {
      return response;
    }

    props.handleUpdatedSuccess();
  };

  const [deleteLocationState, deleteLocation] = useAsyncFn(async () => {
    const response = await LocationsService.deleteById({
      id: props.locationId,
    });
    if (response.hasErrors) {
      var errorMessage = response.errors.reduce((acc, item) => {
        return `${acc} \n ${item.errorMessage}`;
      }, '');

      notifications.error(errorMessage);
    } else {
      props.handleDeletedSuccess();
    }
  });

  const defaultAccountOptions =
    location === undefined
      ? []
      : [
          {
            key: location.accountId,
            text: location.accountNumber,
            value: location.accountId,
          },
        ];

  var component = (
    <AsyncStateContainer {...fetchLocation}>
      {location && (
        <Form.Container>
          <Form
            initialValues={location}
            onSubmit={onSubmit}
            autoComplete="off"
            render={() => (
              <>
                <Form.Section title="Location Information">
                  <FormFields
                    defaultAccountOptions={defaultAccountOptions}
                    fluid={props.fluid}
                    disableAccountSelector={props.disableAccountSelector}
                  />
                </Form.Section>
                <div className="form-actions">
                  <Form.Button type="submit" primary>
                    Update Location
                  </Form.Button>
                  <Button secondary onClick={() => props.handleCancel()}>
                    Cancel
                  </Button>

                  <DeleteButton
                    onConfirm={deleteLocation}
                    loading={deleteLocationState.loading}
                    tertiary
                    icon={false}
                  />
                </div>
              </>
            )}
          />
        </Form.Container>
      )}
    </AsyncStateContainer>
  );

  return {
    component,
    location,
  };
};

type FormFields = {
  defaultAccountOptions?: Array<{key: number; text: string; value: number}>;
  fluid: boolean;
  disableAccountSelector?: boolean;
};

const FormFields = (props: FormFields) => {
  const context = useOrganizationContext();
  const organizationId = context.organizationId as number;

  const [hasMetCharacterLimit, setHasMetCharcterLimit] = useState(false);
  const [accountsLoading, setAccountsLoading] = useState(false);
  const [accountOptions, setAccountOptions] = useState<any>(
    props.defaultAccountOptions ? props.defaultAccountOptions : []
  );

  const accountNumberInput = async (text) => {
    if (text && text.length >= 3) {
      setAccountsLoading(true);
      throttledFetchAccounts(
        text,
        organizationId,
        setAccountOptions,
        setAccountsLoading
      );
    } else {
      setAccountsLoading(false);
    }
  };

  let placeholderText = 'Enter at least 3 characters to begin search.';

  if (hasMetCharacterLimit && accountOptions.length === 0) {
    placeholderText = 'No results found.';
  }

  // eslint-disable-next-line no-restricted-syntax
  console.log('Foo Props', accountOptions);

  return (
    <>
      <Form.Row proportions={props.fluid ? [] : [3, 3]}>
        <Form.Dropdown
          fieldLabel="Associated Account"
          fieldName="accountId"
          onSearchChange={(e, value) => {
            accountNumberInput(value.searchQuery);

            if (value.searchQuery.length > 2) {
              setHasMetCharcterLimit(true);
            } else {
              setHasMetCharcterLimit(false);
            }
          }}
          onBlur={() => {
            setHasMetCharcterLimit(false);
          }}
          options={accountOptions}
          loading={accountsLoading}
          noResultsMessage={placeholderText}
          placeholder="Enter at least 3 characters to begin search."
          fluid
          search
          selection
          clearable
          disabled={props.disableAccountSelector}
        />
      </Form.Row>
      <Form.Row proportions={props.fluid ? [] : [3, 3]}>
        <Form.Input fieldName="locationNumber" fieldLabel="Location Number" />
      </Form.Row>
      <Form.Row proportions={props.fluid ? [] : [3, 3]}>
        <Form.Input fieldName="meterNumber" fieldLabel="Meter Number" />
      </Form.Row>

      <Form.Section title="Address Information">
        <Form.Row proportions={props.fluid ? [] : [3, 3]}>
          <Form.Input fieldName="addressLine1" fieldLabel="Address Line 1" />
        </Form.Row>
        <Form.Row proportions={props.fluid ? [] : [3, 3]}>
          <Form.Input fieldName="addressLine2" fieldLabel="Address Line 2" />
        </Form.Row>
        <Form.Row proportions={props.fluid ? [1, 1, 1] : [1, 1, 1, 3]}>
          <Form.Input fieldName="city" fieldLabel="City" />
          <Form.Dropdown
            fieldName="state"
            fieldLabel="State"
            enum={States}
            selection
            search
          />
          <Form.Input fieldName="zipCode" fieldLabel="Zip Code" />
        </Form.Row>
      </Form.Section>
    </>
  );
};
