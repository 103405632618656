import React, {useState} from 'react';
import {useAsyncRetry} from 'react-use';
import {Button, Modal, Divider, Input} from 'semantic-ui-react';
import {} from '../api/generated/enums';
import {
  AccounteNotesService,
  AccountNoteSummaryDto,
} from '../api/generated';
import moment from 'moment';
import { AccountNoteEditModal, AccountNoteCreateModal } from './account-note-create-update-modal';
import { css } from '@emotion/core';
import { usePagination } from '../hooks/use-pagination';
import { useDebouncedState } from '../hooks/use-debounced-state';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStickyNote } from '@fortawesome/pro-regular-svg-icons';
import {Tooltip} from '../components/tooltip';

type NotesModal = {
  accountId: number;
}

export const NotesModal = (props: NotesModal) => {
  const pagination = usePagination();

  const [fetchAccountNotesList, setFetchAccountNotesList] = useState<AccountNoteSummaryDto[] | undefined>();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [search, setSearch] = useDebouncedState('', 500);

  const [pageCount, setPageCount] = useState<number>();
  const [totalItemCount, setTotalItemCount] = useState<number>();


  const accountId = props.accountId;


  const fetchAccountNotes = useAsyncRetry(async () => {
    if ((isModalOpen) && search) {
      const {data} = await AccounteNotesService.getAll({page: pagination.pageNumber, pageSize: pagination.pageSize, searchFieldNames: ['note', 'userName'], searchSearchText: search});
      setFetchAccountNotesList(data?.items);
      setPageCount(data?.pageCount);
      setTotalItemCount(data?.totalItemCount)
      return data;
    }
    else if((isModalOpen) && !search){
      const {data} = await AccounteNotesService.getAll({page: pagination.pageNumber, pageSize: pagination.pageSize,});
      setFetchAccountNotesList(data?.items);
      setPageCount(data?.pageCount);
      setTotalItemCount(data?.totalItemCount)
      return data;
    }
  }, [isModalOpen, pagination.pageNumber, pagination.pageSize, search]);

  return (
    <div>
      <Modal 
        className="modal-notes"
        css={styles}
        trigger={
          <Tooltip label="Account Notes">
          <Button
            className="clear"
            basic
            icon
            onClick={() => {
                setIsModalOpen(true);
              }}
            >
            <FontAwesomeIcon icon={faStickyNote} size="lg" />
          </Button>
          </Tooltip>
        }
        open={isModalOpen}
        closeOnEscape={isModalOpen}
        closeOnDimmerClick={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        closeIcon
      >
        <Modal.Header>      
          <div className="modal-create-header" css={styles}>
            Acount Notes
           <Input
              style={{marginLeft: "20px"}}
              size={"mini"}
              onChange={(e, {value}) => {
                setSearch(value);
              }}
              icon="search"
              placeholder="Search account notes"
            />
          <div className="modal-create-header-item" css={styles}>

          <AccountNoteCreateModal  onSuccessReFetch={() => {fetchAccountNotes.retry()}} accountId={accountId}/>
          </div>
        </div>
          </Modal.Header>
        <Modal.Content scrolling>
          {fetchAccountNotesList?.length !== 0 ? (fetchAccountNotesList?.map(x => (
            <div>
              <div className="menu-item" css={styles}>
                <label style={{width: 100}}>{x.userName}</label>
                <>
                  <label
                    style={{
                      width: 700,
                      marginLeft: 10,
                      marginRight: 10,
                    }}
                  >
                    {x.note}
                  </label>
                  <div style={{width: 80}}>
                    {moment(x.noteDate).format('MM/DD/YYYY')}
                  </div>
                </>
                <AccountNoteEditModal onSuccessReFetch={() => {fetchAccountNotes.retry()}} id={x.id} ></AccountNoteEditModal>
              </div>
              <Divider/>
            </div>
          ))
          ) : (
          <div style={{textAlign: "center"}}>
            No results for account notes...
          </div>
          )
        }

        </Modal.Content>
        <Modal.Content>
            {pagination.render(
              pageCount || 0,
              totalItemCount || 0,
            )}
        </Modal.Content>
      </Modal>
    </div>
  );
};

const styles = css`
  &.modal-notes {
    position: fixed;
    top: 80px;
    bottom: 1;
  }

  &.menu-item {
    flex-direction: row;
    display: flex;
    justify-content: left;
    align-items: center;
  }

  &.modal-create-header {
    flex-direction: row;
    display: flex;
    align-items: center;
  }

  &.modal-create-header-item {
    flex-direction: row;
    display: flex;
    margin-left: auto;
  }
`;