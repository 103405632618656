import React from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {useAsync, useAsyncFn} from 'react-use';
import {MeterReadingsService, LocationsService} from '../api/generated';
import {BasicPage} from '../basic-page';
import {AsyncStateContainer} from '../components/async-state-container';
import {DeleteButton} from '../components/confirm-delete-button';
import {Form} from '../forms';
import {useNotification} from '../hooks/use-notifications';
import {buildPath, routes} from '../routes';
import {useOrganizationContext} from '../selectors';

export const MeterReadingCreate = () => {
  const history = useHistory();
  const notifications = useNotification();
  const context = useOrganizationContext();
  const organizationId = context.organizationId as number;
  const match = useRouteMatch<{accountId: string}>();
  const accountId = Number(match.params.accountId);

  const fetchLocations = useAsync(async () => {
    const {data} = await LocationsService.getAllByAccountId({
      accountId: accountId,
    });

    return !data ? [] : data.items;
  }, [accountId]);

  const locations = fetchLocations.value || undefined;

  const mappedLocations = (locations || []).map(x => {
    return {
      key: x.id,
      text: x.locationNumber,
      value: x.id,
    };
  });

  const accountDashboardUrl = buildPath(routes.portal.accounts.dashboard, {
    id: accountId,
  });

  const breadcrumbs = [
    {title: 'Meter Readings', url: accountDashboardUrl},
    {title: 'Create'},
  ];

  const onSubmit = async values => {
    values.accountId = accountId;
    values.organizationId = organizationId;

    const response = await MeterReadingsService.create({body: values});
    if (response.hasErrors) {
      return response;
    }

    notifications.success('Meter Reading Created');
    history.goBack();
  };

  return (
    <BasicPage title={breadcrumbs}>
      <AsyncStateContainer {...fetchLocations}>
        {locations && (
          <Form.Container>
            <Form
              onSubmit={onSubmit}
              render={() => (
                <>
                  <Form.Section title="Meter Reading Information">
                    <FormFields
                      locationOptions={mappedLocations}
                      isCreate={true}
                    />
                  </Form.Section>
                  <div className="form-actions">
                    <Form.Button type="submit" primary>
                      Create Meter Reading
                    </Form.Button>
                    <Form.Button
                      type="button"
                      secondary
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      Cancel
                    </Form.Button>
                  </div>
                </>
              )}
            />
          </Form.Container>
        )}
      </AsyncStateContainer>
    </BasicPage>
  );
};

export const MeterReadingDetails = () => {
  const history = useHistory();
  const match = useRouteMatch<{id: string}>();
  const id = Number(match.params.id);
  const notifications = useNotification();

  const fetchMeterReading = useAsync(async () => {
    const {data} = await MeterReadingsService.getById({
      id,
    });
    return data;
  }, [id]);

  const meterReading = fetchMeterReading.value || undefined;

  const mappedLocations = meterReading
    ? [
        {
          key: meterReading.locationId,
          text: meterReading.locationNumber,
          value: meterReading.locationId,
        },
      ]
    : [];

  const breadcrumbs = [
    {title: 'Meter Readings', url: routes.portal.accounts.listing},
  ];

  const onSubmit = async values => {
    const response = await MeterReadingsService.update({id, body: values});
    if (response.hasErrors) {
      return response;
    }
    notifications.success('Meter Reading Updated');
    history.goBack();
  };

  const [deleteMeterReadingState, deleteMeterReading] = useAsyncFn(async () => {
    const response = await MeterReadingsService.deleteById({id});
    if (response.hasErrors) {
      var errorMessage = response.errors.reduce((acc, item) => {
        return `${acc} \n ${item.errorMessage}`;
      }, '');

      notifications.error(errorMessage);
    } else {
      notifications.success('Meter Reading successfully deleted');
      history.push(routes.portal.accounts.listing, {id: id});
    }
  });

  return (
    <BasicPage title={breadcrumbs}>
      <AsyncStateContainer {...fetchMeterReading}>
        {meterReading && (
          <Form.Container>
            <Form
              initialValues={meterReading}
              onSubmit={onSubmit}
              render={({values}) => (
                <>
                  <Form.Section title="Meter Reading Information">
                    <FormFields locationOptions={mappedLocations} />
                  </Form.Section>
                  <div className="form-actions">
                    <Form.Button type="submit" primary>
                      Update Meter Reading
                    </Form.Button>
                    <Form.Button
                      type="button"
                      secondary
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      Cancel
                    </Form.Button>

                    <DeleteButton
                      onConfirm={deleteMeterReading}
                      loading={deleteMeterReadingState.loading}
                      tertiary
                      icon={false}
                    />
                  </div>
                </>
              )}
            />
          </Form.Container>
        )}
      </AsyncStateContainer>
    </BasicPage>
  );
};

type FormFields = {
  locationOptions: Array<{key: number; text: string; value: number}>;
  isCreate?: boolean;
};

const FormFields = (props: FormFields) => {
  return (
    <>
      <Form.Dropdown
        fieldLabel="Location"
        fieldName="locationId"
        options={props.locationOptions}
        disabled={!props.isCreate}
        selection
      />
      <Form.DatePicker fieldName="readingDate" fieldLabel="Reading Date" />
      <Form.Input
        type="number"
        fieldName="readingAmount"
        fieldLabel="Reading Amount"
      />
      <Form.TextArea fieldName="comments" fieldLabel="Comments" />
      <Form.InputCurrency fieldName="amountDue.value" fieldLabel="Amount Due" />
      <Form.InputCurrency
        fieldName="amountReceived.value"
        fieldLabel="Amount Received"
      />
    </>
  );
};
