import React from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {routes} from '../../routes';
import {BasicPage} from '../../basic-page';
import {
  useLocationCreate,
  useLocationDetails,
} from './locaiton-create-update-component';
import {notifications} from '../../utils/notification-service';
import {Form} from '../../forms';

export const LocationCreatePage = () => {
  const history = useHistory();

  const handleCreatedSuccess = () => {
    notifications.success('Location Created');
    history.push(routes.portal.locations.listing);
  };

  const handleCancel = () => {
    history.push(routes.portal.locations.listing);
  };

  const locationCreate = useLocationCreate({
    handleCreatedSuccess,
    handleCancel,
    fluid: false,
  });

  const breadcrumbs = [
    {title: 'Locations', url: routes.portal.locations.listing},
    {title: 'Create'},
  ];

  return <BasicPage title={breadcrumbs}>{locationCreate.component}</BasicPage>;
};

export const LocationDetailsPage = () => {
  const history = useHistory();
  const match = useRouteMatch<{id: string}>();
  const id = Number(match.params.id);

  const handleUpdatedSuccess = () => {
    notifications.success('Location Updated');
    history.push(routes.portal.locations.listing);
  };

  const handleDeletedSuccess = () => {
    notifications.success('Location Deleted');
    history.push(routes.portal.locations.listing);
  };

  const handleCancel = () => {
    history.push(routes.portal.locations.listing);
  };

  const locationDetails = useLocationDetails({
    locationId: id,
    handleCancel,
    handleUpdatedSuccess,
    handleDeletedSuccess,
    fluid: false,
  });

  const breadcrumbs = [
    {title: 'Locations', url: routes.portal.locations.listing},
    {
      title: locationDetails.location
        ? locationDetails.location.locationNumber
        : '',
    },
  ];

  return <BasicPage title={breadcrumbs}>{locationDetails.component}</BasicPage>;
};
