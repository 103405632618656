import css from '@emotion/css/macro';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {lighten} from 'polished';
import React from 'react';
import {NavLink} from 'react-router-dom';
import {routes} from './routes';
import {themeColors} from './styles';
import {Breakpoints, Media} from './styles/breakpoints';
import {User} from './types';
import {
  faBars,
  faBuilding,
  faFileUser,
  faTachometer,
  faMapPin,
} from '@fortawesome/pro-regular-svg-icons';
import {Dropdown, Menu, Responsive} from 'semantic-ui-react';

type PrimaryNavigation = {
  user: User;
};

export const PrimaryNavigation: React.FC<PrimaryNavigation> = () => {
  return (
    <Menu secondary className="top-navigation" css={styles}>
      <Responsive maxWidth={Breakpoints.MobileMax} as={React.Fragment}>
        <MobileNavigation />
      </Responsive>
      <Responsive minWidth={Breakpoints.TabletMin} as={React.Fragment}>
        <DesktopNavigation />
      </Responsive>
    </Menu>
  );
};

const DesktopNavigation = () => (
  <Menu secondary role="navigation" className="desktop-navigation" size="large">
    <Menu.Item as={NavLink} to={routes.portal.meterReadings.dashboard}>
      <FontAwesomeIcon icon={faTachometer} /> Meter Readings
    </Menu.Item>

    <Menu.Item as={NavLink} to={routes.portal.accounts.listing}>
      <FontAwesomeIcon icon={faFileUser} /> Accounts
    </Menu.Item>

    <Menu.Item as={NavLink} to={routes.portal.locations.listing}>
      <FontAwesomeIcon icon={faMapPin} /> Locations
    </Menu.Item>
  </Menu>
);

const MobileNavigation: React.FC = () => (
  <Dropdown
    className="mobile-navigation"
    item
    trigger={
      <span>
        <FontAwesomeIcon icon={faBars} size="lg" />
      </span>
    }
    icon=""
  >
    <Dropdown.Menu>
      <Menu.Item as={NavLink} to={routes.portal.organizations.listing}>
        <FontAwesomeIcon icon={faBuilding} /> Organizations
      </Menu.Item>

      <Menu.Item as={NavLink} to={routes.portal.accounts.listing}>
        <FontAwesomeIcon icon={faFileUser} /> Accounts
      </Menu.Item>
    </Dropdown.Menu>
  </Dropdown>
);

const styles = css`
  &.top-navigation.ui.secondary.menu {
    z-index: 3;
    margin: 0;
    padding: 0 25px 0 10px;

    ${Media('TabletMax')} {
      padding: 0 10px 0 0;
    }

    .ui.header {
      color: ${themeColors.black1};
      font-weight: normal;
    }

    .logo {
      height: 30px;
    }

    .ui.dropdown .menu {
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
    }

    .ui.secondary.menu.desktop-navigation {
      border-bottom-color: #fff !important;

      .item {
        transition: background-color 0.5s ease;
        transition: color 0.5s ease;
        color: ${themeColors.textLabel};
        cursor: pointer;
        text-align: left;
        border-bottom-color: #fff;
        padding-bottom: 1rem;

        &.header {
          margin-top: 10px;
          margin-bottom: 0;
          padding-bottom: 5px;
          cursor: default;
        }

        &:hover:not(.header) {
          color: ${themeColors.black1};
          background: rgba(0, 0, 0, 0.05);
        }

        &.active,
        &.active:hover {
          background-color: ${themeColors.white2};

          > .label {
            background-color: #fff;
            color: ${lighten(0.05, themeColors.black1)};
          }
        }

        &.dropdown {
          .item.active,
          .item.active:hover {
            border: none;
            background-color: #fff;
          }
        }

        > i.icon {
          float: none;
          margin-right: 10px;
        }

        > .label {
          background-color: #767676;
        }
      }

      .logo-container {
        padding: 10px;

        .logo {
          width: 50%;
          margin: 10px auto;
          max-height: 40px;
        }

        .terms {
          display: block;
          text-align: center;
          padding: 10px 0;
        }
      }

      svg {
        width: 1.18em;
        margin: 0 10px 0 0;
        opacity: 0.9;
        color: ${themeColors.black1};
      }
    }

    .ui.dropdown.mobile-navigation {
      svg {
        width: 1.18em;
        margin: 0 10px 0 0;
        opacity: 0.9;
        color: ${themeColors.black1};
      }
    }

    .user-icon.ui.item {
      height: 30px;
      width: 30px;
      display: flex;
      padding: 0;
      border-radius: 30px;
      background: ${themeColors.black1};
      color: #fff;
      align-items: center;
      justify-content: center;
      line-height: 1.5rem;

      &:hover,
      &:focus,
      &:active {
        background: ${themeColors.black1};
      }
    }

    .user-icon-initial {
      display: block;
      text-align: center;
      padding-left: 1px;
      font-size: 1rem;
    }
  }
`;
