import {Env} from '../config/env-vars';

type PathParams = {
  [key: string]: string | number;
};

export const appSubdirectory = Env.subdirectory || '';

export const rootRoutes = {
  callback: `/callback`,
  portal: `/portal`,
  customer: `/customer/:slug`,
};

export const routes = {
  customer: {
    meterReadingInput: `${rootRoutes.customer}`,
    paymentSuccessful: `${rootRoutes.customer}/payment-successful`,
  },
  portal: {
    dashboard: `${rootRoutes.portal}/`,
    users: {
      create: `${rootRoutes.portal}/users/create`,
      detail: `${rootRoutes.portal}/users/:id`,
      listing: `${rootRoutes.portal}/users`,
    },
    organizations: {
      create: `${rootRoutes.portal}/organizations/create`,
      detail: `${rootRoutes.portal}/organizations/current`,
      listing: `${rootRoutes.portal}/organizations`,
    },
    accounts: {
      create: `${rootRoutes.portal}/accounts/create`,
      dashboard: `${rootRoutes.portal}/accounts/dashboard/:id`,
      update: `${rootRoutes.portal}/accounts/update/:id`,
      listing: `${rootRoutes.portal}/accounts`,
    },
    locations: {
      create: `${rootRoutes.portal}/locations/create`,
      detail: `${rootRoutes.portal}/locations/:id`,
      listing: `${rootRoutes.portal}/locations`,
    },
    rateTables: {
      create: `${rootRoutes.portal}/rate-tables/create`,
      dashboard: `${rootRoutes.portal}/rate-tables/dashboard/:id`,
      update: `${rootRoutes.portal}/rate-tables/update/:id`,
      listing: `${rootRoutes.portal}/rate-tables`,
    },
    rateTableTiers: {
      create: `${rootRoutes.portal}/rate-tables/:rateTableId/rate-table-tiers/create`,
      detail: `${rootRoutes.portal}/rate-tables/:rateTableId/rate-table-tiers/:id`,
      listing: `${rootRoutes.portal}/rate-tables/:rateTableId/rate-table-tiers`,
    },
    meterReadings: {
      create: `${rootRoutes.portal}/acounts/:accountId/meter-readings/create`,
      dashboard: `${rootRoutes.portal}/meter-readings-dashboard`,
      detail: `${rootRoutes.portal}/meter-readings/:id`,
      listing: `${rootRoutes.portal}/meter-readings`,
    },
    services: {
      create: `${rootRoutes.portal}/services/create`,
      detail: `${rootRoutes.portal}/services/:id`,
      listing: `${rootRoutes.portal}/services`,
    },
    settings: {
      detail: `${rootRoutes.portal}/settings/:id`,
      listing: `${rootRoutes.portal}/settings`,
    },
  },
};

export const buildPath = (path: string, params: PathParams) => {
  return Object.keys(params).reduce((url, key) => {
    return url.replace(`:${key}`, `${params[key]}`);
  }, path);
};
