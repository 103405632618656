import {faPlus} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useMemo, useCallback} from 'react';
import {Link} from 'react-router-dom';
import {Button} from 'semantic-ui-react';
import {RateTablesService, RateTableSummaryDto} from '../api/generated';
import {BasicPage} from '../basic-page';
import {routes} from '../routes';
import {
  PagedDataTableConfig,
  usePagedDataTable,
  renderViewButton,
} from '../hooks/use-paged-data-table';
import {useOrganizationContext} from '../selectors';
import {AdvancedPagedRequest} from '../types';

type FetchParams = Parameters<
  typeof RateTablesService.getAllByOrganization
>['0'];

export const RateTableListing = () => {
  const context = useOrganizationContext();
  const organizationId = context.organizationId as number;

  const additionalParams = useMemo<FetchParams>(
    () => ({
      organizationId: organizationId,
    }),
    [organizationId]
  );

  const fetchRateTables = useCallback(
    (x: AdvancedPagedRequest<RateTableSummaryDto>) =>
      RateTablesService.getAllByOrganization({
        ...additionalParams,
        ...x,
      } as FetchParams),
    [additionalParams]
  );

  const pagedDataTable = usePagedDataTable(fetchRateTables, TableConfig, {
    actions: (
      <>
        <Button as={Link} to={routes.portal.rateTables.create} primary>
          <FontAwesomeIcon icon={faPlus} /> New Rate Table
        </Button>
      </>
    ),
  });

  return <BasicPage title="Rate Tables">{pagedDataTable}</BasicPage>;
};

const TableConfig = PagedDataTableConfig(
  RateTablesService.getAllByOrganization,
  {
    columns: [
      {
        header: '',
        render: item => (
          <>
            {renderViewButton({
              item,
              descriptor: 'rate-table',
              route: routes.portal.rateTables.dashboard,
            })}
          </>
        ),
        cellProps: {
          collapsing: true,
        },
      },
      {
        header: 'Name',
        column: 'name',
        sortable: 'name',
      },
      {
        header: 'Units',
        column: 'units',
        sortable: 'units',
      },
      {
        header: 'Unit Measurement',
        column: 'unitMeasurement',
        sortable: 'unitMeasurement',
      },
      {
        header: 'Minimum Amount',
        column: 'minimumAmount',
        sortable: 'minimumAmount',
      },
      {
        header: 'Meter Size',
        column: 'meterSize',
        sortable: 'meterSize',
      },
    ],
    searchFieldNames: [
      'name',
      'unitMeasurement',
      'minimumAmount',
      'meterSize',
      'units',
    ],
    defaultSort: {
      column: 'name',
      direction: 'DESC',
    },
  }
);
