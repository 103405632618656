import css from '@emotion/css/macro';
import {faPencil} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import {useAsyncRetry} from 'react-use';
import {Button, Segment} from 'semantic-ui-react';
import {AsyncStateContainer} from '../components/async-state-container';
import {buildPath, routes} from '../routes';
import {} from '../api/generated/enums';
import {RateTablesService} from '../api/generated';
import {useRouteMatch, Link} from 'react-router-dom';
import {BasicPage} from '../basic-page';
import {DataList} from '../components/data-list';
import {Flex} from '../components/flex';
import {RateTableTierListing} from '../rate-table-tiers/rate-table-tier-listing';
import {formatCurrency} from '../components/currency';

export const RateTableDashboard = () => {
  const match = useRouteMatch<{id: string}>();
  const id = Number(match.params.id);

  const fetchRateTable = useAsyncRetry(async () => {
    const {data} = await RateTablesService.getById({id});

    return data;
  }, [id]);

  const rateTable = fetchRateTable.value;

  const url = buildPath(routes.portal.rateTables.update, {
    id: id,
  });

  return (
    <AsyncStateContainer {...fetchRateTable}>
      {rateTable && (
        <>
          <BasicPage
            title={[
              {title: 'Rate Tables', url: routes.portal.rateTables.listing},
              {
                title: 'Rate Tables Details',
              },
            ]}
          >
            <div className="details-flex-container" css={styles}>
              <Segment className="account-card" css={styles}>
                <div className="card-header" style={{marginBottom: '1.375rem'}}>
                  <span style={{fontSize: '1.71428571em', fontWeight: 'bold'}}>
                    {rateTable.name}
                  </span>
                  <div className="edit-button left-buttons">
                    <Flex.Row>
                      <Flex.Box>
                        <Button
                          className="clear"
                          basic
                          icon
                          as={Link}
                          to={url}
                          aria-label={`Edit Rate Table`}
                        >
                          <FontAwesomeIcon icon={faPencil} size="lg" />
                        </Button>
                      </Flex.Box>
                    </Flex.Row>
                  </div>
                </div>
                <DataList
                  data={[
                    {
                      label: 'Name',
                      value: rateTable.name,
                    },
                    {
                      label: 'Units',
                      value: rateTable.units,
                    },
                    {
                      label: 'Meter Size',
                      value: rateTable.meterSize,
                    },
                    {
                      label: 'Minimum Amount',
                      value: rateTable.minimumAmount
                        ? formatCurrency({
                            amount: rateTable.minimumAmount.value,
                          })
                        : 'N/A',
                    },
                    {
                      label: 'Unit Measurement',
                      value: rateTable.unitMeasurement,
                    },
                  ]}
                />
              </Segment>
              <div></div>
            </div>
            <br />
          </BasicPage>
          <RateTableTierListing getRateTableId={() => rateTable.id} />
        </>
      )}
    </AsyncStateContainer>
  );
};

const styles = css`
  &.details-flex-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
  }

  &.account-card {
    margin-bottom: 15px !important;
    margin-top: 0px !important;
    flex: 1;

    .account-balance {
      align-self: flex-start;
      .ui.statistic {
        margin-top: 0;
        > .value {
          font-size: 2rem !important;
        }
      }
    }

    .card-header {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .left-buttons {
        margin-left: auto;
      }
    }
  }
`;
