import React, {useMemo} from 'react';
import {Link, useHistory, useRouteMatch} from 'react-router-dom';
import {useAsync, useAsyncFn} from 'react-use';
import {ServicesService, RateTablesService} from '../api/generated';
import {BasicPage} from '../basic-page';
import {AsyncStateContainer} from '../components/async-state-container';
import {DeleteButton} from '../components/confirm-delete-button';
import {Form} from '../forms';
import {useNotification} from '../hooks/use-notifications';
import {routes} from '../routes';
import {useOrganizationContext} from '../selectors';
import {useFormState} from 'react-final-form';

export const ServiceCreate = () => {
  const history = useHistory();
  const notifications = useNotification();
  const context = useOrganizationContext();
  const organizationId = context.organizationId as number;

  const breadcrumbs = [
    {title: 'Services', url: routes.portal.services.listing},
    {title: 'Create'},
  ];

  const onSubmit = async values => {
    values.organizationId = organizationId;

    const response = await ServicesService.create({body: values});
    if (response.hasErrors) {
      return response;
    }

    notifications.success('Service Created');
    history.push(routes.portal.services.listing);
  };

  return (
    <BasicPage title={breadcrumbs}>
      <Form.Container>
        <Form
          initialValues={{
            fixedPrice: {
              value: 0,
            },
            rateTableId: undefined,
          }}
          onSubmit={onSubmit}
          render={() => (
            <>
              <Form.Section title="Service Information">
                <FormFields />
              </Form.Section>
              <div className="form-actions">
                <Form.Button type="submit" primary>
                  Create Service
                </Form.Button>
                <Form.Button
                  secondary
                  as={Link}
                  to={routes.portal.services.listing}
                >
                  Cancel
                </Form.Button>
              </div>
            </>
          )}
        />
      </Form.Container>
    </BasicPage>
  );
};

export const ServiceDetail = () => {
  const history = useHistory();
  const match = useRouteMatch<{id: string}>();
  const id = Number(match.params.id);
  const notifications = useNotification();

  const fetchService = useAsync(async () => {
    const {data} = await ServicesService.getById({
      id,
    });
    return data;
  }, [id]);

  const service = fetchService.value || undefined;

  //const userName = user ? `${user.firstName} ${user.lastName}` : '';

  const breadcrumbs = [
    {title: 'Services', url: routes.portal.services.listing},
    {title: service ? service.name : ''},
  ];

  const onSubmit = async values => {
    const response = await ServicesService.update({id, body: values});
    if (response.hasErrors) {
      return response;
    }
    notifications.success('Service Updated');
    history.goBack();
  };

  const [deleteServiceState, deleteService] = useAsyncFn(async () => {
    const response = await ServicesService.deleteById({id});
    if (response.hasErrors) {
      var errorMessage = response.errors.reduce((acc, item) => {
        return `${acc} \n ${item.errorMessage}`;
      }, '');

      notifications.error(errorMessage);
    } else {
      notifications.success('Service successfully deleted');
      history.push(routes.portal.services.listing, {id: id});
    }
  });

  return (
    <BasicPage title={breadcrumbs}>
      <AsyncStateContainer {...fetchService}>
        <Form.Container>
          <Form
            initialValues={{
              ...service,
            }}
            onSubmit={onSubmit}
            render={({values}) => (
              <>
                <Form.Section title="Service Information">
                  <FormFields />
                </Form.Section>
                <div className="form-actions">
                  <Form.Button type="submit" primary>
                    Update Service
                  </Form.Button>
                  <Form.Button
                    secondary
                    as={Link}
                    to={routes.portal.services.listing}
                  >
                    Cancel
                  </Form.Button>

                  <DeleteButton
                    onConfirm={deleteService}
                    loading={deleteServiceState.loading}
                    tertiary
                    icon={false}
                  />
                </div>
              </>
            )}
          />
        </Form.Container>
      </AsyncStateContainer>
    </BasicPage>
  );
};

const FormFields = () => {
  const context = useOrganizationContext();
  const organizationId = context.organizationId as number;

  const formState = useFormState();
  // eslint-disable-next-line no-restricted-syntax
  console.log('FormState', formState);

  const fetchRateTables = useAsync(async () => {
    const {data} = await RateTablesService.getAllByOrganization({
      organizationId,
    });

    if (data === null) return null;

    return data.items;
  });

  const rateTables = fetchRateTables.value || undefined;

  const mappedRateTables = useMemo(
    () =>
      (rateTables || []).map(x => ({
        key: `${x.name}`,
        text: `${x.name}`,
        value: x.id,
      })),
    [rateTables]
  );

  return (
    <>
      <Form.Row proportions={[1, 1]}>
        <Form.Input fieldName="name" fieldLabel="Name" />
      </Form.Row>
      <Form.Row proportions={[1, 1, 2]}>
        <Form.InputCurrency
          fieldName="fixedPrice.value"
          fieldLabel="Fixed Price"
          readOnly={formState.values.rateTableId !== undefined}
        />
        <Form.Dropdown
          fieldLabel="Rate Table"
          fieldName="rateTableId"
          disabled={formState.values.fixedPrice.value !== 0}
          options={mappedRateTables}
          search
          selection
          clearable
        />
      </Form.Row>
      <Form.Row proportions={[1, 5]}>
        <Form.Checkbox
          fieldName="setForAllAccountsByDefault"
          fieldLabel="Default On For All Accounts"
          toggle
        />
      </Form.Row>
    </>
  );
};
