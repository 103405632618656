import css from '@emotion/css/macro';
import {includes} from 'lodash';
import React, {useState} from 'react';
import {Checkbox} from 'semantic-ui-react';
import {Button} from './button';

const toggle = (fields, checked, option) => {
  const index = (fields.value || []).findIndex((x) => x === option);
  if (checked) {
    fields.push(option);
  } else if (index > -1) {
    fields.remove(index);
  }
};

export const CheckboxGroup = ({fields, options, label, minShown = 0}) => {
  const [showAll, setShowAll] = useState(false);
  const selected: any[] = fields.value || [];
  const unselected = options.filter((x) => !includes(selected, x));
  const hasUnselected = unselected.length > 0;

  return (
    <div className="form-field" css={styles}>
      <label>{label}</label>

      {options.map((option, index) => {
        const isSelected = includes(fields.value, option);
        const showAlways = minShown === 0 || index < minShown;

        if (!isSelected && !showAll && !showAlways && hasUnselected)
          return null;

        return (
          <div className="inline-label" key={option}>
            <Checkbox
              type="checkbox"
              id={`${fields.name}_${option}`}
              checked={includes(fields.value, option)}
              onChange={(event, {checked}) => toggle(fields, checked, option)}
            />
            <label htmlFor={`${fields.name}_${option}`}>{option}</label>
          </div>
        );
      })}

      {minShown > 0 && options.length > minShown && (
        <div>
          <Button
            type="button"
            className="link"
            onClick={() => {
              setShowAll(!showAll);
            }}
          >
            {showAll ? 'Hide' : 'Show More'}
          </Button>
        </div>
      )}
    </div>
  );
};

const styles = css`
  > label {
    display: block;
    margin: 0em 0em 0.28571429rem 0em;
    font-size: 0.92857143em;
    color: #444;
    font-weight: bold;
    display: block;
  }

  .inline-label {
    display: flex;
    align-items: center;
    margin-bottom: 0.3rem;

    > label {
      flex: 1;
      padding-left: 0.3rem;
      margin-left: 0.3rem;

      &:hover {
        cursor: pointer;
        background: #efefef;
        border-radius: 2px;
      }
    }
  }
`;
