import arrayMutators from 'final-form-arrays';
import React from 'react';
import {FieldArray} from 'react-final-form-arrays';
import {Link, useHistory, useRouteMatch} from 'react-router-dom';
import {useAsync, useAsyncFn} from 'react-use';
import {Divider} from 'semantic-ui-react';
import {UsersService, OrganizationsService} from '../api/generated';
import {AllPermissions, Roles} from '../api/generated/enums';
import {RequireAuthorization} from '../auth/require-authorization';
import {BasicPage} from '../basic-page';
import {AsyncStateContainer} from '../components/async-state-container';
import {DeleteButton} from '../components/confirm-delete-button';
import {Form} from '../forms';
import {CheckboxGroup} from '../forms/checkbox-group';
import {SubmitError} from '../forms/submit-error';
import {useNotification} from '../hooks/use-notifications';
import {routes} from '../routes';

const DEFAULT_USER = {
  firstName: '',
  lastName: '',
  emailAddress: '',
  role: Roles['Global Admin'],
  claims: [],
};

const mutators = {
  ...arrayMutators,
};

export const UserCreate = () => {
  const history = useHistory();
  const notifications = useNotification();

  const breadcrumbs = [
    {title: 'Users', url: routes.portal.users.listing},
    {title: 'Create'},
  ];

  const onSubmit = async (values) => {
    if (values.role === Roles['Global Admin']) {
      values.organizationId = undefined;
    }
    const response = await UsersService.create({body: values});
    if (response.hasErrors) {
      return response;
    }

    notifications.success('User Created');
    history.push(routes.portal.users.listing);
  };

  return (
    <BasicPage title={breadcrumbs}>
      <Form.Container>
        <Form
          autoComplete="off"
          initialValues={DEFAULT_USER}
          mutators={mutators}
          onSubmit={onSubmit}
          render={({values}) => (
            <>
              <FormFields role={values.role} />
              <Divider />
              <div className="form-actions">
                <Form.Button type="submit" primary>
                  Create User
                </Form.Button>
                <Form.Button
                  secondary
                  as={Link}
                  to={routes.portal.users.listing}
                >
                  Cancel
                </Form.Button>
              </div>
              <Form.Debug />
            </>
          )}
        />
      </Form.Container>
    </BasicPage>
  );
};

export const UserDetails = () => {
  const history = useHistory();
  const match = useRouteMatch<{id: string}>();
  const id = Number(match.params.id);
  const notifications = useNotification();

  const fetchUser = useAsync(async () => {
    const {data} = await UsersService.getById({
      id,
    });
    return data;
  }, [id]);

  const user = fetchUser.value || undefined;

  const userName = user ? `${user.firstName} ${user.lastName}` : '';

  const breadcrumbs = [
    {title: 'Users', url: routes.portal.users.listing},
    {title: userName},
  ];

  const onSubmit = async (values) => {
    const response = await UsersService.update({id, body: values});
    if (response.hasErrors) {
      return response;
    }
    notifications.success('User Updated');
    history.push(routes.portal.users.listing);
  };

  const [deleteUserState, deleteUser] = useAsyncFn(async () => {
    const response = await UsersService.deleteById({id});
    if (response.hasErrors) {
      notifications.error('Unable to delete user');
    } else {
      notifications.success('User successfully deleted');
      history.push(routes.portal.users.listing);
    }
  });

  return (
    <BasicPage title={breadcrumbs}>
      <AsyncStateContainer {...fetchUser}>
        {fetchUser && user && (
          <Form.Container>
            <Form
              autoComplete="off"
              initialValues={user}
              mutators={mutators}
              onSubmit={onSubmit}
              render={({values}) => (
                <>
                  <FormFields user={user} role={values.role} />
                  <Divider />
                  <div className="form-actions">
                    <Form.Button type="submit" primary>
                      Update User
                    </Form.Button>
                    <Form.Button
                      secondary
                      as={Link}
                      to={routes.portal.users.listing}
                    >
                      Cancel
                    </Form.Button>

                    <DeleteButton
                      onConfirm={deleteUser}
                      loading={deleteUserState.loading}
                      tertiary
                      icon={false}
                    />
                  </div>
                </>
              )}
            />
          </Form.Container>
        )}
      </AsyncStateContainer>
    </BasicPage>
  );
};

type FormFields = {
  user?: any;
  role: string;
};

const FormFields = ({user, role}: FormFields) => {
  let userClaims = user?.claims;

  const fetchOrganizations = useAsync(async () => {
    const {data} = await OrganizationsService.getAll({
      page: 0,
      pageSize: 0,
    });

    if (data === null) {
      return [];
    }

    return data.items.map((x) => ({
      text: x.name,
      value: x.id,
    }));
  }, []);

  const showOrgs = role !== Roles['Global Admin'];

  return (
    <>
      <Form.Section title="User Details">
        <Form.Row proportions={[2, 2, 1]}>
          <Form.Input fieldName="firstName" fieldLabel="First Name" />
          <Form.Input fieldName="lastName" fieldLabel="Last Name" />
        </Form.Row>

        <Form.Row proportions={[2, 3]}>
          <Form.Input fieldName="emailAddress" fieldLabel="Email Address" />
        </Form.Row>
      </Form.Section>

      <RequireAuthorization role="Global Admin">
        <>
          <Form.Section title="Permissions">
            <Form.Row proportions={[2, 2, 1]}>
              <Form.Dropdown
                fieldName="role"
                fieldLabel="Role"
                enum={Roles}
                selection
              />

              {showOrgs && (
                <Form.Dropdown
                  fieldName="organizationId"
                  fieldLabel="Organization"
                  options={fetchOrganizations.value || []}
                  selection
                />
              )}
            </Form.Row>

            <Form.Row proportions={[2, 3]}>
              <FieldArray
                name="claims"
                options={userClaims}
                render={(props) => (
                  <CheckboxGroup
                    fields={props.fields}
                    options={AllPermissions}
                    label="Claims"
                  />
                )}
              />
            </Form.Row>
            <SubmitError name="claims" />
          </Form.Section>
        </>
      </RequireAuthorization>
    </>
  );
};
