import React from 'react';
import {Link, useHistory} from 'react-router-dom';
import {useAsync, useAsyncFn} from 'react-use';
import {OrganizationsService} from '../api/generated';
import {States} from '../api/generated/enums';
import {BasicPage} from '../basic-page';
import {AsyncStateContainer} from '../components/async-state-container';
import {DeleteButton} from '../components/confirm-delete-button';
import {Form} from '../forms';
import {useNotification} from '../hooks/use-notifications';
import {routes} from '../routes';
import {useOrganizationContext} from '../selectors';
import {notify} from '../hooks/use-subscription';

export const OrganizationCreate = () => {
  const history = useHistory();
  const notifications = useNotification();

  const breadcrumbs = [
    {title: 'Organizations', url: routes.portal.organizations.listing},
    {title: 'Create'},
  ];

  const onSubmit = async (values) => {
    const response = await OrganizationsService.create({body: values});
    if (response.hasErrors) {
      return response;
    }

    notifications.success('Organization Created');
    history.push(routes.portal.organizations.listing);
  };

  return (
    <BasicPage title={breadcrumbs}>
      <Form.Container>
        <Form
          onSubmit={onSubmit}
          render={() => (
            <>
              <Form.Section title="Organization Information">
                <FormFields />
              </Form.Section>
              <div className="form-actions">
                <Form.Button type="submit" primary>
                  Create Organization
                </Form.Button>
                <Form.Button
                  secondary
                  as={Link}
                  to={routes.portal.organizations.listing}
                >
                  Cancel
                </Form.Button>
              </div>
            </>
          )}
        />
      </Form.Container>
    </BasicPage>
  );
};

export const OrganizationDetails = () => {
  const history = useHistory();
  const notifications = useNotification();

  const context = useOrganizationContext();
  const organizationId = context.organizationId as number;

  const fetchOrganization = useAsync(async () => {
    const {data} = await OrganizationsService.getById({
      id: organizationId,
    });
    return data;
  }, [organizationId]);

  const organization = fetchOrganization.value || undefined;

  //const userName = user ? `${user.firstName} ${user.lastName}` : '';

  const breadcrumbs = [
    {title: 'Organizations', url: routes.portal.organizations.listing},
    {title: organization ? organization.name : ''},
  ];

  const onSubmit = async (values) => {
    const response = await OrganizationsService.update({
      id: organizationId,
      body: values,
    });
    if (response.hasErrors) {
      return response;
    }
    notifications.success('Organization Updated');
    notify('organization-updated', {organizationId});
    history.push(routes.portal.organizations.listing);
  };

  const [deleteOrganizationState, deleteOrganization] = useAsyncFn(async () => {
    const response = await OrganizationsService.deleteById({
      id: organizationId,
    });
    if (response.hasErrors) {
      var errorMessage = response.errors.reduce((acc, item) => {
        return `${acc} \n ${item.errorMessage}`;
      }, '');

      notifications.error(errorMessage);
    } else {
      notifications.success('Organization successfully deleted');
      history.push(routes.portal.organizations.listing);
    }
  });

  return (
    <BasicPage title={breadcrumbs}>
      <AsyncStateContainer {...fetchOrganization}>
        <Form.Container>
          <Form
            initialValues={organization}
            onSubmit={onSubmit}
            render={({values}) => (
              <>
                <Form.Section title="Organization Information">
                  <FormFields organization="organization" />
                </Form.Section>
                <div className="form-actions">
                  <Form.Button type="submit" primary>
                    Update Organization
                  </Form.Button>
                  <Form.Button
                    secondary
                    as={Link}
                    to={routes.portal.organizations.listing}
                  >
                    Cancel
                  </Form.Button>

                  <DeleteButton
                    onConfirm={deleteOrganization}
                    loading={deleteOrganizationState.loading}
                    tertiary
                    icon={false}
                  />
                </div>
              </>
            )}
          />
        </Form.Container>
      </AsyncStateContainer>
    </BasicPage>
  );
};

const FormFields = (organization) => {
  return (
    <>
      <Form.Input fieldName="name" fieldLabel="Name" />
      <Form.Input fieldName="slug" fieldLabel="Slug" />
      <Form.Input
        type="number"
        fieldName="billingDay"
        fieldLabel="Billing Day of Month"
      />
      <Form.Input
        fieldName="meterReadingInstructionsLink"
        fieldLabel="Meter Reading Instructions Link"
      />
      <Form.Input
        fieldName="rateTableInstructionsLink"
        fieldLabel="Rate Table Instructions Link"
      />

      <Form.Section title="Address Information">
        <Form.Input fieldName="addressLine1" fieldLabel="Address Line 1" />
        <Form.Input fieldName="addressLine2" fieldLabel="Address Line 2" />
        <Form.Row>
          <Form.Input fieldName="city" fieldLabel="City" />
          <Form.Dropdown
            fieldName="state"
            fieldLabel="State"
            enum={States}
            selection
            search
          />
          <Form.Input fieldName="zipCode" fieldLabel="Zip Code" />

          <Form.CustomValue fieldName="_" render={() => false} />
          <Form.CustomValue fieldName="_" render={() => false} />
          <Form.CustomValue fieldName="_" render={() => false} />
        </Form.Row>
      </Form.Section>
    </>
  );
};
