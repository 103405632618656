import {faPlus} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useMemo, useCallback} from 'react';
import {Link} from 'react-router-dom';
import {Button} from 'semantic-ui-react';
import {LocationsService, LocationSummaryDto} from '../api/generated';
import {BasicPage} from '../basic-page';
import {routes} from '../routes';
import {
  PagedDataTableConfig,
  usePagedDataTable,
  renderEditButton,
} from '../hooks/use-paged-data-table';
import {useOrganizationContext} from '../selectors';
import {AdvancedPagedRequest} from '../types';

type FetchParams = Parameters<
  typeof LocationsService.getAllByOrganizationId
>['0'];

export const LocationListing = () => {
  const context = useOrganizationContext();
  const organizationId = context.organizationId as number;

  const additionalParams = useMemo<FetchParams>(
    () => ({
      organizationId: organizationId,
    }),
    [organizationId]
  );

  const fetchLocations = useCallback(
    (x: AdvancedPagedRequest<LocationSummaryDto>) =>
      LocationsService.getAllByOrganizationId({
        ...additionalParams,
        ...x,
      } as FetchParams),
    [additionalParams]
  );

  const pagedDataTable = usePagedDataTable(fetchLocations, TableConfig, {
    actions: (
      <Button as={Link} to={routes.portal.locations.create} primary>
        <FontAwesomeIcon icon={faPlus} /> New Location
      </Button>
    ),
  });

  return <BasicPage title="Locations">{pagedDataTable}</BasicPage>;
};

const TableConfig = PagedDataTableConfig(
  LocationsService.getAllByOrganizationId,
  {
    columns: [
      {
        header: '',
        render: item => (
          <>
            {renderEditButton({
              item,
              descriptor: 'locations',
              route: routes.portal.locations.detail,
            })}
          </>
        ),
        cellProps: {
          collapsing: true,
        },
      },
      {
        header: 'Location Number',
        column: 'locationNumber',
        sortable: 'locationNumber',
      },
      {
        header: 'Meter Number',
        column: 'meterNumber',
        sortable: 'meterNumber',
      },
      {
        header: 'Address',
        render: item => (
          <>
            <div>{item.addressLine1}</div>
            <div>{item.addressLine2}</div>
            {`${item.city}, ${item.state}`}
            {item.zipCode && `, ${item.zipCode}`}
          </>
        ),
      },
    ],
    searchFieldNames: [
      'locationNumber',
      'meterNumber',
      'addressLine1',
      'addressLine2',
      'city',
      'state',
      'zipCode',
    ],
    defaultSort: {
      column: 'locationNumber',
      direction: 'DESC',
    },
  }
);
