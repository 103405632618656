import {css} from '@emotion/core';

export const customerStyles = css`
  .divider {
    width: 80%;
    margin: auto;
    margin-top: 35px;
    margin-bottom: 35px;
    border-top: 2px solid lightgray;
  }

  .center-content-box {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .meter-reading-main-content {
    margin-top: 150px;
    margin-bottom: 50px;
    padding-bottom: 50px;
    width: 600px;
    position: relative;
    padding-top: 160px;
    background-color: white;
    -webkit-box-shadow: -7px 10px 5px -1px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: -7px 10px 5px -1px rgba(0, 0, 0, 0.4);
    box-shadow: -7px 10px 5px -1px rgba(0, 0, 0, 0.4);
  }

  .circle-content {
    width: 250px;
    height: 250px;
    border-radius: 50%;
    text-align: center;
    position: absolute;
    top: -125px;
    right: 0;
    left: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: #262e3b;
  }

  .meter-image {
    filter: invert(1);
  }

  .customer-header-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .customer-name {
    font-size: 2.5rem;
  }

  .account-information {
    padding-top: 15px;
    font-size: 1.5rem;
  }

  .centerd-body-content {
    width: 80%;
    margin: auto;
  }

  .previous-meter-reading {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 80%;
    margin: auto;
  }

  .previous-meter-reading .title {
    font-size: 2rem;
    margin-bottom: 30px;
    text-align: center;
  }

  .previous-meter-reading .reading-information {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
    width: 90%;
    font-size: 1.5rem;
  }

  .new-meter-reading {
    width: 70%;
    margin: auto;
  }

  .payment-options-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 80%;
    margin: auto;
  }

  .payment-options-container .title {
    font-size: 2rem;
    margin-bottom: 30px;
    text-align: center;
  }

  .pay-with-paystar {
    margin-top: 1.5rem;
    width: 17rem;
    height: 4rem;
    font-size: 1.5rem;
  }

  .small-text {
    font-size: 1.1rem;
  }

  .medium-text {
    font-size: 1.4rem;
  }

  .large-text {
    font-size: 1.9rem;
  }

  .center-horizontal {
    text-align: center;
  }

  .bold {
    font-weight: bold;
  }

  .amount-due-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 80%;
    height: 100%;
    margin: auto;
  }

  .amount-due-container .title {
    font-size: 2rem;
    margin-bottom: 30px;
    text-align: center;
  }

  .go-back-span {
    cursor: pointer;
    color: blue;
    text-decoration: underline;
  }
`;
