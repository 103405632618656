import css from '@emotion/css/macro';
import {reduce, values} from 'lodash';
import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';
import Select, {defaultTheme} from 'react-select';
import {Header, Menu as SemanticMenu} from 'semantic-ui-react';
import 'string_score';
import {useSubscription} from './hooks/use-subscription';
import {setContext, fetchOrganizations} from './store';
import {payStarColors} from './styles/styled';
import {logger} from './utils/logger';

import {useOrganizationContext, useOrganizations} from './selectors';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSort} from '@fortawesome/pro-solid-svg-icons';
import {ellipsis} from 'polished';
import {useHistory} from 'react-router-dom';
import {routes} from './routes';

type SelectorOption = {
  value: number;
  label: string;
};

const {colors} = defaultTheme;

const ContextSelector = () => {
  const dispatch = useDispatch();
  const context = useOrganizationContext();
  const organizations = useOrganizations();
  const history = useHistory();

  const log = logger('ContextSelector');

  useSubscription('organization-updated', (e) => {
    log.info('organization-updated', e);
    dispatch(fetchOrganizations());
    if (context.organizationId) {
      dispatch(setContext(context.organizationId));
    }
  });

  const options = useMemo<SelectorOption[]>(() => {
    return values(
      reduce(
        organizations,
        (contextMap, organization) => {
          let orgFromSelectorGroup: SelectorOption =
            contextMap[organization.id];

          if (!orgFromSelectorGroup) {
            orgFromSelectorGroup = {
              label: organization.name,
              value: organization.id,
            };

            contextMap[organization.id] = orgFromSelectorGroup;
          }

          return contextMap;
        },
        {}
      )
    );
  }, [organizations]);

  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState<SelectorOption>(() => {
    const key = `${context.organizationId || ''}`;

    var bar = options.find((x) => x.value.toString() === key) as SelectorOption;

    return bar;
  });

  useEffect(() => {
    const organizationId = context.organizationId || undefined;

    const selectedOption = options.find((x) => x.value === organizationId);

    setValue(selectedOption as SelectorOption);
  }, [context, options]);

  function toggleOpen() {
    setIsOpen(!isOpen);
  }

  function onSelectChange(value: SelectorOption) {
    dispatch(setContext(value.value));
    toggleOpen();
    history.push(routes.portal.dashboard);
  }

  return (
    <Dropdown
      isOpen={isOpen}
      onClose={toggleOpen}
      target={
        <>
          <SemanticMenu.Item onClick={toggleOpen}>
            <Header className="selected-organization">
              <Header.Subheader>Organization</Header.Subheader>
              {value ? <div>{value.label}</div> : <div> Error </div>}
            </Header>
            <FontAwesomeIcon icon={faSort} />
          </SemanticMenu.Item>
        </>
      }
      css={styles}
    >
      <Select
        autoFocus
        backspaceRemovesValue={false}
        components={{DropdownIndicator, IndicatorSeparator: null}}
        controlShouldRenderValue={false}
        hideSelectedOptions={false}
        isClearable={false}
        menuIsOpen
        onChange={onSelectChange}
        filterOption={filterOptions}
        options={options}
        placeholder="Search..."
        styles={selectStyles}
        tabSelectsValue={false}
        value={value}
        maxMenuHeight={600}
        onKeyDown={(e) => {
          if (e.keyCode === 27) {
            setIsOpen(false);
          }
        }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: payStarColors.primary.blue,
            primary: payStarColors.blue5,
          },
        })}
      />
    </Dropdown>
  );
};

const selectStyles = {
  control: (provided: any) => ({...provided, minWidth: 180, margin: 8}),
  menu: () => ({boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)'}),
  option: (provided, state) => {
    let color = '#000';
    let backgroundColor = '#FFF';
    let fontWeight = 'normal';
    let fontStyle = 'normal';

    if (state.isFocused) {
      backgroundColor = payStarColors.blue3;
      color = '#FFF';
    }

    if (state.isSelected) {
      fontWeight = 'bold';
      fontStyle = 'italic';
    }
    return {
      ...provided,
      color,
      backgroundColor,
      fontStyle,
      fontWeight,
      paddingLeft: 25,
    };
  },
};

const filterOptions = (candidate, input) => {
  if (input) {
    const {organizationName = ''} = candidate.data;
    return (
      organizationName.score(input) > 0.4 ||
      candidate.label.score(input, 0.5) > 0.5
    );
  }
  return true;
};

const styles = css`
  .ui.header {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .item {
    border-radius: 0 !important;
    border-left: solid 1px #e4e4e4 !important;
    border-right: solid 1px #e4e4e4 !important;
    padding: 0.585714em 1.92857143em 0.185714em !important;
    margin-right: 2rem !important;

    @media only screen and (max-width: 500px) {
      margin-right: 0 !important;
    }

    .selected-organization > * {
      ${ellipsis(200)}
    }

    [data-icon] {
      color: #5f5f5f;
      margin-left: 15px !important;
    }
  }
`;

const dropdownStyles = css`
  position: relative;
  @media only screen and (max-width: 767px) {
    overflow-x: hidden;
  }
`;

const Menu = (props) => <div css={menuStyles} {...props} />;

const menuStyles = css`
  margin-top: 5px;
  position: absolute;
  z-index: 2;
  width: auto;
  padding: 0 10px;

  @media only screen and (max-width: 767px) {
    position: fixed;
    z-index: 10;
  }

  > div {
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 0 0 1px hsla(218, 50%, 10%, 0.1),
      0 4px 11px hsla(218, 50%, 10%, 0.1);
    padding-top: 1px;
  }
`;

const Blanket = (props) => (
  <div
    css={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: 'fixed',
      zIndex: 1,
    }}
    {...props}
  />
);

const Dropdown = ({children, isOpen, target, onClose, className = ''}) => (
  <div css={dropdownStyles} className={className}>
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
);

const Svg = (p) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    focusable="false"
    role="presentation"
    {...p}
  />
);

const DropdownIndicator = () => (
  <div css={{color: colors.neutral20, height: 24, width: 32}}>
    <Svg>
      <path
        d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </Svg>
  </div>
);

export {ContextSelector};
