import React, {useState} from 'react';
import {LocationsService} from '../api/generated';
import {Form} from '../forms';
import {useNotification} from '../hooks/use-notifications';
import {useOrganizationContext} from '../selectors';
import {throttle} from 'lodash';
import {Popup, Button} from 'semantic-ui-react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus} from '@fortawesome/pro-regular-svg-icons';
import {useAsyncFn} from 'react-use';

const fetchLocations = async (
  text,
  organizationId,
  setLocationOptions,
  setLocationsLoading
) => {
  const {data} = await LocationsService.getAllByOrganizationId({
    searchFieldNames: ['LocationNumber'],
    searchSearchText: text,
    organizationId: organizationId,
  });

  const options = !data
    ? []
    : data.items.map(x => {
        return {
          key: x.id,
          text: x.locationNumber,
          value: x.id,
        };
      });

  setLocationOptions(options);
  setLocationsLoading(false);
};

const throttledFetchLocations = throttle(fetchLocations, 1000);

type AddExistingLocationToAccountModal = {
  accountId: number;
  onSubmitSuccess?: () => void;
};

export const useAddExistingLocationToAccount = (
  props: AddExistingLocationToAccountModal
) => {
  const notifications = useNotification();
  const [open, setOpen] = useState(false);

  const [associateLocationState, associateLocation] = useAsyncFn(
    async (...args) => {
      const [values] = args;
      values.accountId = props.accountId;

      const response = await LocationsService.associateLocationWithAccount({
        body: values,
      });

      return response;
    }
  );

  const onSubmit = async values => {
    var response = await associateLocation(values);

    if (response.hasErrors) {
      notifications.error(
        response.errors.reduce((acc, error) => {
          return acc + `\r\n${error.errorMessage}`;
        }, '')
      );
    } else {
      notifications.success('Location associated with Account');

      if (props.onSubmitSuccess) {
        props.onSubmitSuccess();
      }
    }
  };

  const loading = associateLocationState.loading;

  const component = (
    <Popup
      trigger={
        <Button primary>
          <FontAwesomeIcon icon={faPlus} /> Existing Location
        </Button>
      }
      on="click"
      flowing
      position="bottom right"
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
    >
      <Form
        onSubmit={onSubmit}
        render={() => (
          <>
            <FormFields />
            <Form.Button type="submit" primary>
              Add
            </Form.Button>
            <Form.Button
              type="button"
              onClick={() => {
                setOpen(false);
              }}
              secondary
            >
              Cancel
            </Form.Button>
          </>
        )}
      />
    </Popup>
  );

  return {
    component,
    setOpen,
    state: {
      loading,
    },
  };
};

const FormFields = () => {
  const context = useOrganizationContext();
  const organizationId = context.organizationId as number;

  const [hasMetCharacterLimit, setHasMetCharcterLimit] = useState(false);
  const [locationsLoading, setLocationsLoading] = useState(false);
  const [locationOptions, setLocationOptions] = useState<any>([]);

  const onLocationNumberInput = async text => {
    if (text && text.length >= 3) {
      setLocationsLoading(true);
      throttledFetchLocations(
        text,
        organizationId,
        setLocationOptions,
        setLocationsLoading
      );
    } else {
      setLocationsLoading(false);
    }
  };

  let placeholderText = 'Type at least 3 characters';

  if (hasMetCharacterLimit && locationOptions.length === 0) {
    placeholderText = 'No results found.';
  }

  return (
    <>
      <Form.Row>
        <Form.Dropdown
          fieldLabel="Locations"
          fieldName="locationId"
          onSearchChange={(e, value) => {
            onLocationNumberInput(value.searchQuery);

            if (value.searchQuery.length > 2) {
              setHasMetCharcterLimit(true);
            } else {
              setHasMetCharcterLimit(false);
            }
          }}
          onBlur={() => {
            setHasMetCharcterLimit(false);
          }}
          options={locationOptions}
          loading={locationsLoading}
          noResultsMessage={placeholderText}
          placeholder="Type to search"
          search
          selection
        />
      </Form.Row>
    </>
  );
};
