import css from '@emotion/css/macro';
import {faSort, faSortDown, faSortUp} from '@fortawesome/pro-duotone-svg-icons';
import {faFileInvoice} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {cx} from 'emotion';
import {isFunction} from 'lodash';
import {transparentize} from 'polished';
import React, {ReactNode, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {useAsyncRetry} from 'react-use';
import {useDebouncedState} from './use-debounced-state';
import {useDelayedExpiration} from './use-delay-expiration';
import {SubscriptionEvents, useSubscription} from './use-subscription';
import {AdvancedQueryPage, Response} from '../api/generated';
import {Alert} from '../components/alert';
import {CopyButton} from '../components/copy-button';
import {Flex} from '../components/flex';
import {Tooltip} from '../components/tooltip';
import {usePagination} from '../hooks/use-pagination';
import {buildPath} from '../routes';
import {themeColors} from '../styles';
import {Media} from '../styles/breakpoints';
import {notifications} from '../utils/notification-service';
/* eslint-disable no-restricted-syntax */

import {
  faPencil,
  IconDefinition,
  faFilter,
} from '@fortawesome/pro-regular-svg-icons';
import {
  KeysOfType,
  RequireOnlyOne,
  AdvancedPagedSearch,
  AdvancedPagedRequest,
} from '../types';
import {
  Header,
  Icon,
  Segment,
  Table,
  TableCellProps,
  Input,
  Button,
  TableRowProps,
  Loader,
  Dimmer,
  TableProps,
} from 'semantic-ui-react';

type ColumnRenderProps<TDto> = RequireOnlyOne<
  {
    column?: KeysOfType<TDto, any>;
    render?: (item: TDto) => ReactNode;
  },
  'column' | 'render'
>;

type ColumnConfig<TDto> = {
  header: string | React.ReactNode;
  cellProps?: TableCellProps;
  copy?: boolean | ((item: TDto) => string);
  sortable?: KeysOfType<TDto, any>;
} & ColumnRenderProps<TDto>;

type RenderButton = {
  item: {id: number};
  route: string;
  descriptor: string;
  fontAwesomeIcon?: IconDefinition;
  disabled?: boolean;
};

type RenderEditButton = {
  item: {id: number};
  route: string;
  descriptor: string;
};

type RenderViewButton = {
  item: {id: number};
  route: string;
  descriptor: string;
};

type RuntimeConfig<TDto, TAdditionalRequestParams = {}> = {
  actions?: React.ReactNode;
  filter?: {
    getParams: () => TAdditionalRequestParams;
    render: () => JSX.Element;
  };
};

type SortState<TDto> = {
  column: KeysOfType<TDto, string>;
  direction: 'ASC' | 'DESC';
};

export type PagedDataTableConfig<TDto> = {
  columns: ColumnConfig<TDto>[];
  defaultSort: SortState<TDto>;
  searchFieldNames: KeysOfType<TDto, string>[];
  rowProps?: (item: TDto) => TableRowProps;
  tableProps?: TableProps;
  refetchEvent?: SubscriptionEvents;
};

export function usePagedDataTable<TDto>(
  pagedFetchAction: (
    request: AdvancedPagedRequest<TDto>
  ) => Promise<Response<AdvancedQueryPage<TDto>>>,
  config: PagedDataTableConfig<TDto>,
  runtimeConfig?: RuntimeConfig<TDto>
): ReactNode;
export function usePagedDataTable<TDto, TAdditionalRequestParams>(
  pagedFetchAction: (
    request: AdvancedPagedRequest<TDto> & TAdditionalRequestParams
  ) => Promise<Response<AdvancedQueryPage<TDto>>>,
  config: PagedDataTableConfig<TDto>,
  runtimeConfig?: RuntimeConfig<TDto, TAdditionalRequestParams>
): ReactNode {
  const pagination = usePagination();
  const [search, setSearch] = useDebouncedState('', 350);
  const [showFilters, setShowFilters] = useState(false);
  const [sortState, setSortState] = useState<SortState<TDto>>(
    () => config.defaultSort
  );

  const cachedResult = useRef<AdvancedQueryPage<TDto> | null | undefined>();
  const filterState = runtimeConfig?.filter?.getParams();

  const fetchData = useAsyncRetry(async () => {
    const searchParams: AdvancedPagedSearch<TDto> = search
      ? {
          searchFieldNames: config.searchFieldNames,
          searchSearchText: search,
        }
      : ({} as AdvancedPagedSearch<TDto>);

    const sortParams: AdvancedPagedRequest<TDto> = sortState
      ? {
          sorted: [
            {
              fieldName: sortState.column as string,
              descending: sortState.direction === 'DESC',
            },
          ],
        }
      : {};

    const params = {
      page: pagination.pageNumber,
      pageSize: pagination.pageSize,
      ...searchParams,
      ...sortParams,
      ...filterState,
    };

    try {
      const {data, hasErrors} = await pagedFetchAction(
        params as Parameters<typeof pagedFetchAction>['0']
      );
      if (hasErrors) {
        notifications.error('Failed to fetch data');
      } else {
        cachedResult.current = data;
        return data;
      }
    } catch (error) {
      notifications.error('Failed to fetch data');
    }
  }, [
    config.searchFieldNames,
    filterState,
    pagedFetchAction,
    pagination.pageNumber,
    pagination.pageSize,
    search,
    sortState,
  ]);

  useSubscription(config.refetchEvent || '__NEVER__', () => {
    fetchData.retry();
  });

  const showLoading = useDelayedExpiration({
    isActive: fetchData.loading,
    delayInMs: 350,
  });

  const normalizedData = normalizedAdvancedPagedResult<TDto>(
    cachedResult.current
  );

  return (
    <>
      <Segment css={styles}>
        <Flex.Row align="center">
          <Flex.Fill>
            <Input
              onChange={(e, {value}) => {
                setSearch(value);
              }}
              icon="search"
              placeholder="Search"
              className="table-search"
              action={
                runtimeConfig?.filter && (
                  <>
                    <Button
                      icon
                      basic
                      onClick={() => setShowFilters(!showFilters)}
                    >
                      <FontAwesomeIcon icon={faFilter} />
                    </Button>
                  </>
                )
              }
              actionPosition={runtimeConfig?.filter && 'left'}
            />
          </Flex.Fill>
          <Flex.Box>{runtimeConfig?.actions}</Flex.Box>
        </Flex.Row>
        {showFilters && runtimeConfig?.filter && runtimeConfig.filter.render()}
        {fetchData.error ? (
          <Alert negative>{fetchData.error.message}</Alert>
        ) : normalizedData.items.length === 0 && !fetchData.loading ? (
          <Segment placeholder className="no-results">
            <Header icon>
              <Icon name="search" size="tiny" />
              No results found
            </Header>
          </Segment>
        ) : normalizedData.items.length > 0 ? (
          <>
            <Dimmer.Dimmable className="table-container">
              <Table
                basic="very"
                compact
                className="paged-table"
                unstackable
                {...config.tableProps}
              >
                <Table.Header>
                  <Table.Row>
                    {config.columns.map((column, columnIndex) => {
                      const key =
                        (column.column as string) || `column_${columnIndex}`;

                      let sortIcon: IconDefinition | undefined;

                      if (column.sortable) {
                        sortIcon =
                          column.sortable &&
                          sortState?.column === (column.sortable as string)
                            ? sortState.direction === 'ASC'
                              ? faSortUp
                              : faSortDown
                            : faSort;
                      }

                      return (
                        <Table.HeaderCell
                          {...column.cellProps}
                          key={key}
                          className={cx(
                            column.cellProps?.className,
                            column.sortable && 'sortable'
                          )}
                          onClick={
                            column.sortable &&
                            (() => {
                              const direction =
                                sortState?.column !==
                                (column.sortable as string)
                                  ? 'ASC'
                                  : sortState?.direction === 'DESC'
                                  ? 'ASC'
                                  : 'DESC';

                              setSortState({
                                column: column.sortable as any,
                                direction,
                              });
                            })
                          }
                        >
                          {sortIcon && <FontAwesomeIcon icon={sortIcon} />}
                          {column.header}
                        </Table.HeaderCell>
                      );
                    })}
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {normalizedData.items.map((item, itemIndex) => {
                    const rowProps = config.rowProps?.(item) ?? {};

                    return (
                      <Table.Row key={`item_${itemIndex}`} {...rowProps}>
                        {config.columns.map((column, columnIndex) => {
                          const key =
                            (column.column as string) ||
                            `column_${columnIndex}`;

                          let copyValue: string = '';

                          if (column.copy) {
                            copyValue = isFunction(column.copy)
                              ? column.copy(item)
                              : !column.render
                              ? ((item[column.column] as unknown) as string)
                              : '';
                          }

                          return (
                            <Table.Cell {...column.cellProps} key={key}>
                              <span
                                className={cx(copyValue && 'has-copy-icon')}
                              >
                                {column.render
                                  ? column.render(item)
                                  : item[column.column]}
                                {copyValue && (
                                  <CopyButton
                                    value={copyValue}
                                    size="tiny"
                                    className="hidden-row-action"
                                    tabIndex="-1"
                                  />
                                )}
                              </span>
                            </Table.Cell>
                          );
                        })}
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
              <Dimmer active={showLoading} inverted />
              <Loader active={showLoading} />
            </Dimmer.Dimmable>
            {pagination.render(
              normalizedData.pageCount,
              normalizedData.totalItemCount
            )}
          </>
        ) : (
          <Loader inline="centered" active={showLoading} />
        )}
      </Segment>
    </>
  );
}

export function PagedDataTableConfig<TDto>(
  pagedFetchAction: (
    request: AdvancedPagedRequest<TDto>
  ) => Promise<Response<AdvancedQueryPage<TDto>>>,
  config: PagedDataTableConfig<TDto>
): PagedDataTableConfig<TDto> {
  return config;
}

export const renderButton: React.FC<RenderButton> = (props) => {
  const {item, route, descriptor} = props;
  const url = buildPath(route, {
    id: item.id,
  });
  return (
    <Tooltip label={`${descriptor}`}>
      <Button
        className="clear"
        basic
        icon
        as={Link}
        to={url}
        aria-label={`${descriptor}`}
        disabled={props.disabled}
      >
        <FontAwesomeIcon
          icon={props.fontAwesomeIcon ? props.fontAwesomeIcon : faFileInvoice}
        />
      </Button>
    </Tooltip>
  );
};

export const renderViewButton: React.FC<RenderViewButton> = (props) => {
  const {item, route, descriptor} = props;
  const url = buildPath(route, {
    id: item.id,
  });
  return (
    <Tooltip label={`View ${descriptor}`}>
      <Button
        className="clear"
        basic
        icon
        as={Link}
        to={url}
        aria-label={`View ${descriptor}`}
      >
        <FontAwesomeIcon icon={faFileInvoice} />
      </Button>
    </Tooltip>
  );
};

export const renderEditButton: React.FC<RenderEditButton> = (props) => {
  const {item, route, descriptor} = props;
  const url = buildPath(route, {
    id: item.id,
  });
  return (
    <Tooltip label={`Edit ${descriptor}`}>
      <Button className="clear" basic icon as={Link} to={url}>
        <FontAwesomeIcon icon={faPencil} />
      </Button>
    </Tooltip>
  );
};

const DEFAULT_RESULT = {
  items: [],
  page: 0,
  pageSize: 0,
  pageCount: 0,
  totalItemCount: 0,
};

function normalizedAdvancedPagedResult<T>(
  value: AdvancedQueryPage<T> | null | undefined
): AdvancedQueryPage<T> {
  return value || DEFAULT_RESULT;
}

const styles = css`
  ${Media('MobileMax')} {
    &.ui.segment {
      margin: 0 -1rem;
      border-radius: 0;
    }
  }

  .table-search {
    input {
      border-width: 2px;
      border-color: #cbcfd1;
    }

    &.left.action {
      input {
        margin-left: -1px;
      }

      .ui.button {
        box-shadow: none !important;
        z-index: 0;
      }
    }
  }

  .table-container {
    overflow-x: auto;
    margin: 2em 0em;
  }

  .paged-table.ui.table {
    thead th {
      white-space: nowrap;
      padding: 0.528571em 0.78571429em !important;

      &.sortable {
        user-select: none;
        cursor: pointer;

        &:hover {
          background-color: #f5f5f5;
        }

        svg {
          margin-right: 0.2rem;
        }
      }
    }
  }

  .has-copy-icon {
    white-space: nowrap;
    position: relative;
  }

  .copy-button {
    position: relative;
    opacity: 0;
    margin-left: 0 !important;
    margin-right: -5px !important;
    background-color: ${transparentize(0.2, themeColors.white1)} !important;
    border: solid 1px ${transparentize(0.2, themeColors.white1)} !important;
    height: 36px;
    width: 36px;
    position: absolute;
    top: 50%;
    right: -38px;
    margin-top: -18px;

    &:hover {
      background-color: ${themeColors.white1} !important;
      border-color: inherit !important;
    }
  }

  td:hover .copy-button {
    opacity: 1;
  }

  .no-results .ui.icon.header .icon {
    line-height: 2.5rem;
    font-size: 2rem;
  }
`;
