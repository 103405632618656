import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {PaymentSuccessful} from '../customers/payment-successfull';
import {routes} from '.';
import {SearchAccountsOrLoadInputMeterReading} from '../customers/meter-reading-input';

export const CustomerRoutes = () => {
  return (
    <Switch>
      <Route path={routes.customer.paymentSuccessful} exact>
        <PaymentSuccessful />
      </Route>
      <Route path={routes.customer.meterReadingInput} exact>
        <SearchAccountsOrLoadInputMeterReading />
      </Route>
    </Switch>
  );
};
