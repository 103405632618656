import React, {useMemo} from 'react';
import arrayMutators from 'final-form-arrays';
import {Link, useHistory, useRouteMatch} from 'react-router-dom';
import {useAsync, useAsyncFn} from 'react-use';
import {
  AccountsService,
  RateTablesService,
  ServicesService,
} from '../api/generated';
import {BasicPage} from '../basic-page';
import {AsyncStateContainer} from '../components/async-state-container';
import {DeleteButton} from '../components/confirm-delete-button';
import {Form} from '../forms';
import {useNotification} from '../hooks/use-notifications';
import {routes} from '../routes';
import {useOrganizationContext} from '../selectors';
import {AccountStatuses} from '../api/generated/enums';
import {FieldArray} from 'react-final-form-arrays';
import {CheckboxGroup} from '../forms/checkbox-group';

const mutators = {
  ...arrayMutators,
};

export const AccountCreate = () => {
  const history = useHistory();
  const notifications = useNotification();
  const context = useOrganizationContext();
  const organizationId = context.organizationId as number;

  const fetchRateTables = useAsync(async () => {
    const {data} = await RateTablesService.getAllByOrganization({
      organizationId,
    });

    if (data === null) return null;

    return data.items;
  });

  const rateTables = fetchRateTables.value || undefined;

  const mappedRateTables = useMemo(
    () =>
      (rateTables || []).map((x) => ({
        key: `${x.name}`,
        text: `${x.name}`,
        value: x.id,
      })),
    [rateTables]
  );

  const fetchServices = useAsync(async () => {
    const {data} = await ServicesService.getAllByOrganization({
      organizationId,
    });

    if (data === null) return null;

    return data.items;
  });

  const services = fetchServices.value || undefined;

  const allServices = useMemo(() => (services || []).map((x) => x.name), [
    services,
  ]);

  const appliedServices = useMemo(
    () =>
      (services || []).reduce((acc, service) => {
        if (service.setForAllAccountsByDefault) {
          acc.push(service.name);
        }
        return acc;
      }, [] as string[]),
    [services]
  );

  const breadcrumbs = [
    {title: 'Accounts', url: routes.portal.accounts.listing},
    {title: 'Create'},
  ];

  const onSubmit = async (values) => {
    values.organizationId = organizationId;
    values.appliedServicesIds = values.appliedServices.map((x) => {
      return services?.find((service) => service.name === x)?.id;
    });

    const response = await AccountsService.create({body: values});
    if (response.hasErrors) {
      return response;
    }

    notifications.success('Account Created');
    history.push(routes.portal.accounts.listing);
  };

  return (
    <BasicPage title={breadcrumbs}>
      <Form.Container>
        <Form
          onSubmit={onSubmit}
          mutators={mutators}
          initialValues={{appliedServices: appliedServices}}
          render={() => (
            <>
              <Form.Section title="Account Information">
                <FormFields
                  rateTableOptions={mappedRateTables}
                  allServiceOptions={allServices}
                  appliedServiceOptions={appliedServices}
                />
              </Form.Section>
              <div className="form-actions">
                <Form.Button type="submit" primary>
                  Create Account
                </Form.Button>
                <Form.Button
                  secondary
                  as={Link}
                  to={routes.portal.accounts.listing}
                >
                  Cancel
                </Form.Button>
              </div>
            </>
          )}
        />
      </Form.Container>
    </BasicPage>
  );
};

export const AccountUpdate = () => {
  const history = useHistory();
  const match = useRouteMatch<{id: string}>();
  const id = Number(match.params.id);
  const notifications = useNotification();
  const context = useOrganizationContext();
  const organizationId = context.organizationId as number;

  const fetchAccount = useAsync(async () => {
    const {data} = await AccountsService.getById({
      id,
    });
    return data;
  }, [id]);

  const account = fetchAccount.value || undefined;

  const fetchRateTables = useAsync(async () => {
    const {data} = await RateTablesService.getAllByOrganization({
      organizationId,
    });

    if (data === null) return null;

    return data.items;
  });

  const rateTables = fetchRateTables.value || undefined;

  const mappedRateTables = useMemo(
    () =>
      (rateTables || []).map((x) => ({
        key: `${x.name}`,
        text: `${x.name}`,
        value: x.id,
      })),
    [rateTables]
  );

  const fetchServices = useAsync(async () => {
    const {data} = await ServicesService.getAllByOrganization({
      organizationId,
    });

    if (data === null) return null;

    return data.items;
  });

  const services = fetchServices.value || undefined;

  const allServices = useMemo(() => (services || []).map((x) => x.name), [
    services,
  ]);

  const appliedServices = useMemo(
    () =>
      (services || []).reduce((acc, service) => {
        if (
          account?.appliedServicesIds.some(
            (serviceId) => service.id === serviceId
          )
        ) {
          acc.push(service.name);
        }
        return acc;
      }, [] as string[]),
    [services, account]
  );

  const breadcrumbs = [
    {title: 'Accounts', url: routes.portal.accounts.listing},
    {title: account ? account.name : ''},
  ];

  const onSubmit = async (values) => {
    values.appliedServicesIds = values.appliedServices.map((x) => {
      return services?.find((service) => service.name === x)?.id;
    });

    const response = await AccountsService.update({id, body: values});
    if (response.hasErrors) {
      return response;
    }
    notifications.success('Account Updated');
    history.goBack();
  };

  const [deleteAccountState, deleteAccount] = useAsyncFn(async () => {
    const response = await AccountsService.deleteById({id});
    if (response.hasErrors) {
      var errorMessage = response.errors.reduce((acc, item) => {
        return `${acc} \n ${item.errorMessage}`;
      }, '');

      notifications.error(errorMessage);
    } else {
      notifications.success('Account successfully deleted');
      history.push(routes.portal.accounts.listing, {id: id});
    }
  });

  return (
    <BasicPage title={breadcrumbs}>
      <AsyncStateContainer {...fetchAccount}>
        <Form.Container>
          <Form
            initialValues={{...account, appliedServices: appliedServices}}
            mutators={mutators}
            onSubmit={onSubmit}
            render={({values}) => (
              <>
                <Form.Section title="Account Information">
                  <FormFields
                    rateTableOptions={mappedRateTables}
                    allServiceOptions={allServices}
                    appliedServiceOptions={appliedServices}
                  />
                </Form.Section>
                <div className="form-actions">
                  <Form.Button type="submit" primary>
                    Update Account
                  </Form.Button>
                  <Form.Button
                    secondary
                    as={Link}
                    to={routes.portal.accounts.listing}
                  >
                    Cancel
                  </Form.Button>

                  <DeleteButton
                    onConfirm={deleteAccount}
                    loading={deleteAccountState.loading}
                    tertiary
                    icon={false}
                  />
                </div>
              </>
            )}
          />
        </Form.Container>
      </AsyncStateContainer>
    </BasicPage>
  );
};

type FormFields = {
  rateTableOptions: {
    key: string;
    text: string;
    value: number;
  }[];
  allServiceOptions: string[];
  appliedServiceOptions: string[];
};

const FormFields = (props: FormFields) => {
  return (
    <>
      <Form.Row proportions={[1, 1]}>
        <Form.Input fieldName="accountNumber" fieldLabel="Account Number" />
      </Form.Row>
      <Form.Row proportions={[1, 1, 2]}>
        <Form.Dropdown
          fieldLabel="Rate Table"
          fieldName="rateTableId"
          options={props.rateTableOptions}
          search
          selection
        />
        <Form.Dropdown
          fieldLabel="Status"
          fieldName="status"
          enum={AccountStatuses}
          search
          selection
        />
      </Form.Row>
      <Form.Row proportions={[1, 1]}>
        <Form.Input fieldName="name" fieldLabel="Name" />
      </Form.Row>
      <Form.Row proportions={[1, 1]}>
        <Form.Input fieldName="email" fieldLabel="Email" />
      </Form.Row>
      <Form.Row proportions={[1, 1]}>
        <Form.InputMasked
          fieldName="phoneNumber"
          fieldLabel="Phone Number"
          placeholder="XXX-XXX-XXXX"
          options={{
            phone: true,
            phoneRegionCode: 'US',
            delimiter: '-',
          }}
        />
      </Form.Row>
      <Form.Row>
        <FieldArray
          name="appliedServices"
          options={props.appliedServiceOptions}
          render={(arrayProps) => {
            return (
              <CheckboxGroup
                fields={arrayProps.fields}
                options={props.allServiceOptions}
                label="Services"
              />
            );
          }}
        />
      </Form.Row>
    </>
  );
};
