// Enums

export type ClientIds = keyof typeof ClientIds;
export const ClientIds = {web: 'web'} as const;

export type CustomClaims = keyof typeof CustomClaims;
export const CustomClaims = {permissions: 'permissions', role: 'role'} as const;

export type CustomSessionClaims = keyof typeof CustomSessionClaims;
export const CustomSessionClaims = {
  organization_id: 'organization_id',
  organization_slug: 'organization_slug',
} as const;

export type AuthenticationQueryKeys = keyof typeof AuthenticationQueryKeys;
export const AuthenticationQueryKeys = {
  OrganizationSlug: 'OrganizationSlug',
} as const;

export type CurrencyCodes = keyof typeof CurrencyCodes;
export const CurrencyCodes = {USD: 'USD'} as const;

export type UnitTypes = keyof typeof UnitTypes;
export const UnitTypes = {
  'Cubic Feet': 'Cubic Feet',
  Gallons: 'Gallons',
} as const;

export type Timezones = keyof typeof Timezones;
export const Timezones = {
  'US/Pacific': 'US/Pacific',
  'US/Mountain': 'US/Mountain',
  'US/Central': 'US/Central',
  'US/Eastern': 'US/Eastern',
} as const;

export type UriProtocols = keyof typeof UriProtocols;
export const UriProtocols = {
  'http://': 'http://',
  'https://': 'https://',
} as const;

export type WebClientDirectories = keyof typeof WebClientDirectories;
export const WebClientDirectories = {
  portal: 'portal',
  customer: 'customer',
} as const;

export type ReportNames = keyof typeof ReportNames;
export const ReportNames = {
  'meter-readings-excel': 'meter-readings-excel',
} as const;

export type AccountStatuses = keyof typeof AccountStatuses;
export const AccountStatuses = {
  Due: 'Due',
  Paid: 'Paid',
  Late: 'Late',
  Disconnect: 'Disconnect',
  Shutoff: 'Shutoff',
  Reconnect: 'Reconnect',
} as const;

export type States = keyof typeof States;
export const States = {
  AL: 'AL',
  AK: 'AK',
  AS: 'AS',
  AZ: 'AZ',
  AR: 'AR',
  CA: 'CA',
  CO: 'CO',
  CT: 'CT',
  DE: 'DE',
  DC: 'DC',
  FM: 'FM',
  FL: 'FL',
  GA: 'GA',
  GU: 'GU',
  HI: 'HI',
  ID: 'ID',
  IL: 'IL',
  IN: 'IN',
  IA: 'IA',
  KS: 'KS',
  KY: 'KY',
  LA: 'LA',
  ME: 'ME',
  MH: 'MH',
  MD: 'MD',
  MA: 'MA',
  MI: 'MI',
  MN: 'MN',
  MS: 'MS',
  MO: 'MO',
  MT: 'MT',
  NE: 'NE',
  NV: 'NV',
  NH: 'NH',
  NJ: 'NJ',
  NM: 'NM',
  NY: 'NY',
  NC: 'NC',
  ND: 'ND',
  MP: 'MP',
  OH: 'OH',
  OK: 'OK',
  OR: 'OR',
  PW: 'PW',
  PA: 'PA',
  PR: 'PR',
  RI: 'RI',
  SC: 'SC',
  SD: 'SD',
  TN: 'TN',
  TX: 'TX',
  UT: 'UT',
  VT: 'VT',
  VI: 'VI',
  VA: 'VA',
  WA: 'WA',
  WV: 'WV',
  WI: 'WI',
  WY: 'WY',
} as const;

export type UserPermissions = keyof typeof UserPermissions;
export const UserPermissions = {
  'Users: View': 'Users: View',
  'Users: Manage': 'Users: Manage',
} as const;

export type OrganizationPermissions = keyof typeof OrganizationPermissions;
export const OrganizationPermissions = {
  'Organizations: View': 'Organizations: View',
  'Organizations: Manage': 'Organizations: Manage',
} as const;

export type AccountPermissions = keyof typeof AccountPermissions;
export const AccountPermissions = {
  'Accounts: View': 'Accounts: View',
  'Accounts: Manage': 'Accounts: Manage',
} as const;

export type RateTablePermissions = keyof typeof RateTablePermissions;
export const RateTablePermissions = {
  'Rate Tables: View': 'Rate Tables: View',
  'Rate Tables: Manage': 'Rate Tables: Manage',
} as const;

export type RateTableTierPermissions = keyof typeof RateTableTierPermissions;
export const RateTableTierPermissions = {
  'Rate Table Tiers: View': 'Rate Table Tiers: View',
  'Rate Table Tiers: Manage': 'Rate Table Tiers: Manage',
} as const;

export type MeterReadingPermissions = keyof typeof MeterReadingPermissions;
export const MeterReadingPermissions = {
  'Meter Readings: View': 'Meter Readings: View',
  'Meter Readings: Manage': 'Meter Readings: Manage',
} as const;

export type PaymentPermissions = keyof typeof PaymentPermissions;
export const PaymentPermissions = {
  'Payments: View': 'Payments: View',
  'Payments: Manage': 'Payments: Manage',
} as const;

export type ServicePermissions = keyof typeof ServicePermissions;
export const ServicePermissions = {
  'Services: View': 'Services: View',
  'Services: Manage': 'Services: Manage',
} as const;

export type Roles = keyof typeof Roles;
export const Roles = {
  'Global Admin': 'Global Admin',
  'Organization Admin': 'Organization Admin',
  User: 'User',
  'Quick Pay': 'Quick Pay',
  'System User': 'System User',
} as const;

// Statics

export const RoleTemplates = {
  'Global Admin': [
    'Users: View',
    'Users: Manage',
    'Organizations: View',
    'Organizations: Manage',
    'Accounts: View',
    'Accounts: Manage',
    'Rate Table Tiers: View',
    'Rate Table Tiers: Manage',
  ],
  'Organization Admin': [
    'Users: View',
    'Users: Manage',
    'Organizations: View',
    'Organizations: Manage',
    'Accounts: View',
    'Accounts: Manage',
    'Rate Table Tiers: View',
    'Rate Table Tiers: Manage',
  ],
} as const;

export const AllPermissions = [
  'Users: View',
  'Users: Manage',
  'Organizations: View',
  'Organizations: Manage',
  'Accounts: View',
  'Accounts: Manage',
  'Rate Table Tiers: View',
  'Rate Table Tiers: Manage',
] as const;
