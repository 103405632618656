import moment, {Moment} from 'moment';
import React, {useState} from 'react';
import {useAsync, useAsyncFn} from 'react-use';
import {Button} from 'semantic-ui-react';
import {customerStyles} from './customer-styles';
import {AsyncStateContainer} from '../components/async-state-container';
import {Form} from '../forms';
import meterReadingLogo from '../images/meter-reader-balanced-black.png';
import {notifications} from '../utils/notification-service';
import {useHasAuthorization} from '../auth/authorization-helpers';

import {
  AccountsService,
  MeterReadingsService,
  AccountForCustomer,
  IntegrationsService,
} from '../api/generated';
import {userManager} from '../auth';
import {Flex} from '../components/flex';
import {useRouteMatch, useHistory} from 'react-router-dom';
import {useQuery} from '../hooks/use-query';
import {AuthenticationQueryKeys} from '../api/generated/enums';
import {routes} from '../routes';

export const SearchAccountsOrLoadInputMeterReading = React.memo(() => {
  const query = useQuery();

  const [accountNumber, setAccountNumber] = useState(
    query.get('accountNumber')
  );
  const match = useRouteMatch<{slug: string}>();
  const slug = match.params.slug;

  const isAdmin = useHasAuthorization({
    role: ['Global Admin', 'Organization Admin'],
  });

  const fetchAccount = useAsync(async () => {
    // eslint-disable-next-line no-restricted-syntax
    console.log('foo accountNumber', accountNumber, isAdmin, slug);

    if (isAdmin) {
      if (!accountNumber) {
        return undefined;
      }

      const response = await AccountsService.getByAccountNumberForCustomerPortal(
        {
          accountNumber: accountNumber,
          organizationSlug: slug,
        }
      );

      if (response.hasErrors) {
        response.errors.forEach((x) => notifications.error(x.errorMessage));
      }

      return response;
    }

    const response = await AccountsService.getByCurrentUser();
    return response;
  }, [accountNumber, isAdmin, slug]);

  const accountResponse = fetchAccount?.value || undefined;

  return (
    <>
      {isAdmin &&
      (!accountNumber || !accountResponse || accountResponse.hasErrors) ? (
        <BaseMeterReading>
          <div className="centerd-body-content">
            <div className="large-text">Search for an account</div>
            <br />
            <Form
              onSubmit={(values) => {
                setAccountNumber(values.accountNumber);
              }}
              render={() => (
                <>
                  <Form.Row>
                    <Form.Input
                      fieldName="accountNumber"
                      fieldLabel="Account Number"
                      fluid
                    ></Form.Input>
                  </Form.Row>
                  <Button primary fluid type="submit">
                    Search
                  </Button>
                </>
              )}
            ></Form>
          </div>
        </BaseMeterReading>
      ) : (
        accountResponse &&
        accountResponse.data && (
          <AsyncStateContainer {...fetchAccount}>
            <MeterReadingInput account={accountResponse.data} />
          </AsyncStateContainer>
        )
      )}
    </>
  );
});

const BaseMeterReading = React.memo(({children}) => {
  return (
    <div css={customerStyles}>
      <div className="center-content-box">
        <div className="meter-reading-main-content">
          <div className="circle-content">
            <img
              className="meter-image"
              alt="meter reader logo"
              src={meterReadingLogo}
              height="250"
              width="250"
            />
          </div>
          {children}
        </div>
      </div>
    </div>
  );
});

type MeterReadingInput = {
  account: AccountForCustomer;
};

export const MeterReadingInput = React.memo<MeterReadingInput>(({account}) => {
  const [stage, setStage] = useState(0);
  const [createdMeterReadingId, setCreatedMeterReadingId] = useState(0);

  const getActiveComponent = (account: AccountForCustomer) => {
    switch (stage) {
      case 0:
        return (
          <InputMeterReading
            stage={stage}
            setStage={setStage}
            account={account}
            setCreatedMeterReadingId={setCreatedMeterReadingId}
          ></InputMeterReading>
        );
      case 1:
        return (
          <PayMeterReading
            stage={stage}
            setStage={setStage}
            account={account}
            createdMeterReadingId={createdMeterReadingId}
            setCreatedMeterReadingId={setCreatedMeterReadingId}
          ></PayMeterReading>
        );
    }
  };

  return <BaseMeterReading>{getActiveComponent(account)}</BaseMeterReading>;
});

type InputMeterReading = {
  stage: number;
  setStage: (number) => void;
  account: AccountForCustomer;
  setCreatedMeterReadingId: (number) => void;
};

const InputMeterReading = (props: InputMeterReading) => {
  const handleMeterReadingSubmition = async (values) => {
    values.accountId = props.account.id;
    values.locationId = props.account.location.id;
    values.calculateAmountDue = true;

    const response = await MeterReadingsService.create({body: values});

    if (!response.data) {
      return response;
    }

    if (response.hasErrors) {
      return response;
    }

    props.setCreatedMeterReadingId(response.data.id);
    props.setStage(1);
  };

  return (
    <>
      <div className="customer-header-content">
        <span className="customer-name">{props.account.name}</span>
        <span className="account-information muted">
          <span className="small-text">Account Number: </span>
          {props.account.accountNumber}
        </span>
      </div>

      <div className="divider" />

      <div className="previous-meter-reading-container">
        <div className="previous-meter-reading">
          <div className="title">Previous Meter Reading</div>
          <div className="reading-information">
            <div className="date">
              <span className="small-text">Date: </span>
              <span className="medium-text bold">
                {moment(
                  props.account.location.previousMeterReading.readingDate
                ).format('MM/DD/YYYY')}
              </span>
            </div>
            <div className="amount">
              <span className="small-text">Reading Amount: </span>
              <span className="medium-text bold">
                {props.account.location.previousMeterReading.readingAmount}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="divider" />

      <div className="new-meter-reading">
        <Form
          onSubmit={handleMeterReadingSubmition}
          render={() => (
            <>
              <Form.Row>
                <Form.Input
                  fieldName="readingAmount"
                  fieldLabel="Current Meter Reading"
                />
                <Form.DatePicker
                  fieldName="readingDate"
                  fieldLabel="Reading Date"
                  initialVisibleMonth={() =>
                    moment(
                      props.account.location.previousMeterReading.readingDate
                    )
                  }
                  isOutsideRange={(date) =>
                    date.isBefore(
                      moment(
                        props.account.location.previousMeterReading.readingDate
                      ).add(1, 'day')
                    )
                  }
                  withPortal
                />
              </Form.Row>
              <Form.Row>
                <Form.Input
                  fieldName="comments"
                  fieldLabel="Comments (optional)"
                />
              </Form.Row>
              <Form.Button type="submit" primary fluid>
                Submit
              </Form.Button>
            </>
          )}
        />
      </div>
    </>
  );
};

type PayMeterReading = {
  stage: number;
  setStage: (number) => void;
  account: AccountForCustomer;
  createdMeterReadingId: number;
  setCreatedMeterReadingId: (number) => void;
};

const PayMeterReading = (props: PayMeterReading) => {
  const history = useHistory();
  const match = useRouteMatch<{slug: string}>();
  const slug = match.params.slug;

  const isAdmin = useHasAuthorization({
    role: ['Global Admin', 'Organization Admin'],
  });

  const [handlePayWithPayStarState, handlePayWithPayStar] = useAsyncFn(
    async () => {
      const response = await IntegrationsService.postPaymentToPayStar({
        body: {accountId: (props.account.id as unknown) as number},
      });

      if (!response.data) {
        return;
      }

      if (response.hasErrors) {
        response.errors.map((x) => notifications.error(x.errorMessage));
      } else {
        window.location.assign(response.data.paymentLogInLink);
      }
    }
  );

  const fetchCostOfMeterReadings = useAsync(async () => {
    const response = await MeterReadingsService.getCostBetweenRecentMeterReadings(
      {accountId: (props.account.id as unknown) as number}
    );

    return response;
  });

  const costOfMeterReadings = fetchCostOfMeterReadings.value || undefined;

  const handleGoBack = async () => {
    var confirmGoBack = window.confirm(
      'This will delete the meter entry you just submitted. You will need to resubmit a meter reading if you go back.'
    );

    if (confirmGoBack) {
      var response = await MeterReadingsService.deleteByIdForCustomerPortal({
        id: props.createdMeterReadingId,
      });

      if (response.hasErrors) {
        response.errors.forEach((x) => notifications.error(x.errorMessage));
      } else {
        props.setCreatedMeterReadingId(0);
        props.setStage(0);
      }
    }
  };

  return (
    <AsyncStateContainer {...fetchCostOfMeterReadings}>
      {costOfMeterReadings && (
        <>
          <div className="customer-header-content">
            <span className="customer-name">{props.account.name}</span>
            <span className="account-information muted">
              <span className="small-text">Account Number: </span>
              {props.account.accountNumber}
            </span>
          </div>

          <div className="divider" />

          <div className="amount-due-container">
            <span className="title">Amount due for this billing cycle</span>

            <div>
              <span className="medium-text center-horizontal">$</span>
              <span className="large-text bold">
                {costOfMeterReadings.data !== null
                  ? `${(costOfMeterReadings.data.cost.value / 100).toFixed(2)}`
                  : 'Error'}
              </span>
            </div>
            <br />
            <div>
              Is this not correct?{' '}
              <span className="go-back-span" onClick={handleGoBack}>
                Click here
              </span>{' '}
              to resubmit.
            </div>
          </div>

          <div className="divider" />

          <div className="payment-options-container">
            <span className="title">Payment Options</span>
            <Flex.Col>
              <Flex.Box>
                <Flex.Box>
                  <Button
                    className="pay-with-paystar"
                    primary
                    fluid
                    onClick={handlePayWithPayStar}
                    loading={handlePayWithPayStarState.loading}
                  >
                    Pay Now
                  </Button>
                  <Button
                    className="pay-with-paystar"
                    secondary
                    fluid
                    onClick={() => {
                      if (isAdmin) {
                        history.push(routes.portal.accounts.listing);
                      } else {
                        userManager.signoutRedirect({
                          extraQueryParams: {
                            [AuthenticationQueryKeys.OrganizationSlug]: slug,
                          },
                        });
                      }
                    }}
                  >
                    {isAdmin ? 'Return to Accounts' : 'Exit and Pay Offline'}
                  </Button>
                </Flex.Box>
              </Flex.Box>
            </Flex.Col>
            <div></div>
          </div>
        </>
      )}
    </AsyncStateContainer>
  );
};
