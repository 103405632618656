import {faPlus} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useMemo, useCallback} from 'react';
import {Link} from 'react-router-dom';
import {Button} from 'semantic-ui-react';
import {ServicesService, ServiceSummaryDto} from '../api/generated';
import {BasicPage} from '../basic-page';
import {routes} from '../routes';
import {
  PagedDataTableConfig,
  usePagedDataTable,
  renderEditButton,
} from '../hooks/use-paged-data-table';
import {useOrganizationContext} from '../selectors';
import {AdvancedPagedRequest} from '../types';

type FetchParams = Parameters<typeof ServicesService.getAllByOrganization>['0'];

export const ServiceListing = () => {
  const context = useOrganizationContext();
  const organizationId = context.organizationId as number;

  const additionalParams = useMemo<FetchParams>(
    () => ({
      organizationId: organizationId,
    }),
    [organizationId]
  );

  const fetchServices = useCallback(
    (x: AdvancedPagedRequest<ServiceSummaryDto>) =>
      ServicesService.getAllByOrganization({
        ...additionalParams,
        ...x,
      } as FetchParams),
    [additionalParams]
  );

  const pagedDataTable = usePagedDataTable(fetchServices, TableConfig, {
    actions: (
      <>
        <Button as={Link} to={routes.portal.services.create} primary>
          <FontAwesomeIcon icon={faPlus} /> New Service
        </Button>
      </>
    ),
  });

  return <BasicPage title="Services">{pagedDataTable}</BasicPage>;
};

const TableConfig = PagedDataTableConfig(ServicesService.getAllByOrganization, {
  columns: [
    {
      header: '',
      render: (item) => (
        <>
          {renderEditButton({
            item,
            descriptor: 'Service',
            route: routes.portal.services.detail,
          })}
        </>
      ),
      cellProps: {
        collapsing: true,
      },
    },
    {
      header: 'Name',
      column: 'name',
      sortable: 'name',
    },
    {
      header: 'Fixed Price',
      column: 'fixedPrice',
      sortable: 'fixedPrice',
    },
    {
      header: 'Rate Table',
      column: 'rateTableName',
      sortable: 'rateTableName',
    },
    {
      header: 'Default On',
      render: (item) => <>{item.setForAllAccountsByDefault ? 'Yes' : ''}</>,
    },
  ],
  searchFieldNames: ['name', 'fixedPrice', 'rateTableName'],
  defaultSort: {
    column: 'name',
    direction: 'DESC',
  },
});
